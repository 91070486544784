import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Contact.css'
import { Header, Footer, Popular } from '../../Home/HomeElements/index'
import { useLanguage } from '../../../LanguageContext';

const loadCartDataFromLocalStorage = () => {
  const savedCartData = localStorage.getItem('cart');
  if (savedCartData) {
    return JSON.parse(savedCartData);
  }
  return [];
};

const Contact = () => {
  const { language } = useLanguage();
  const translations = require(`../../../Language/${language}.json`);
  useEffect(() => {
    const referrerUrl = document.referrer;
    const platform = window.navigator.platform;
    let simplifiedPlatform;

    // Map platform values to more user-friendly names
    if (platform.includes('Win')) {
      simplifiedPlatform = 'Windows';
    } else {
      simplifiedPlatform = platform; // Use the original value if not recognized
    }
    axios
      .post(`https://nodetlbnjib.tlbnjib.com/add-view`, {referrerUrl, simplifiedPlatform})
      .then((res) => {
        
      })
      .catch((err) => console.log(err));
  }, []);
  // get main data
  const [data, setData] = useState({});
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/')
      .then(res => {
        setData(res.data);
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);

  // cart
  // Access the data from the location's state property
  const [CartData, setCartData] = useState(loadCartDataFromLocalStorage());

  useEffect(() => {
    // Save cart data to local storage whenever it changes
    localStorage.setItem('cart', JSON.stringify(CartData));
  }, [CartData]);
  useEffect(() => {
    // Function to add the quantity property to cart items, but only if it doesn't exist
    const addQuantityToCartItems = (cartItems) => {
      return cartItems.map((item) => ({
        ...item,
        quantity: item.quantity || 1, // Set quantity to 1 if it doesn't exist
      }));
    };
  
    // Load cart data from local storage
    const loadedCartData = loadCartDataFromLocalStorage();
  
    // Update cart data with quantity property, but only if it doesn't exist
    const updatedCartData = addQuantityToCartItems(loadedCartData);
  
    // Set the updated cart data
    setCartData(updatedCartData);
  }, []);
  const [Contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  })
  const handleSubmitContact = (e) => {
    e.preventDefault();
    axios
      .post('https://nodetlbnjib.tlbnjib.com/add-contact', Contact)
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    document.title = "Contactez-vous | TLBN'JIB"
  })
  return (
    <div className={language==='fr'? 'fontFamilyFr': 'fontFamilyAr ar'} dir={language === 'fr' ? "ltr" : "rtl"}>
      <Header numphone={data.numPhone} cart={CartData} />
      <div className='contact'>
        <h2 className='contact-title'>{translations.Contacteznous}</h2>
        <div className='form-inputs-info'>
            <div className='form-contact'>
                <form method='POST' onSubmit={handleSubmitContact}>
                    <div className='flex-co'>
                        <div className='flex-col-in'>
                            <input type='text' className={language === 'ar'?'input-cont fontFamilyAr':'input-cont'} value={Contact.name} onChange={(e) => setContact({...Contact, name: e.target.value})} name='name' placeholder={translations.formCompleteOrder} required/>
                            <input type='text' className={language === 'ar'?'input-cont fontFamilyAr':'input-cont'} value={Contact.email} onChange={(e) => setContact({...Contact, email: e.target.value})} name='email' placeholder={translations.EmailOp} />
                        </div>
                        <div className='flex-col-in'>
                            <input type='text' className={language === 'ar'?'input-cont fontFamilyAr':'input-cont'} value={Contact.phone} onChange={(e) => setContact({...Contact, phone: e.target.value})} name='num' placeholder={translations.formCompleteOrder1} required/>
                            <input type='text' className={language === 'ar'?'input-cont fontFamilyAr':'input-cont'} value={Contact.subject} onChange={(e) => setContact({...Contact, subject: e.target.value})} name='subject' placeholder={translations.MessageSubject} required/>
                        </div>
                    </div>
                    <textarea className={language === 'ar' ? 'input-message fontFamilyAr' : 'input-message'} name='message' value={Contact.message} onChange={(e) => setContact({...Contact, message: e.target.value})} placeholder={translations.ecriremess} required></textarea>
                    <button className={language === 'ar'?'btn-contact-send fontFamilyAr': 'btn-contact-send'}>{translations.Envoyer}</button>
                </form>
            </div>
            <div className='info-email-phon'>
                <p className='info-content-fb'>{translations.Add}: {data.address}</p>
                <p className='info-content-fb'>{translations.Email}: {data.email}</p>
                <p className='info-content-fb'>{translations.telephone}: {data.numPhone}</p>
            </div>
        </div>
      </div>
      <Footer numphone={data.numPhone} emaild={data.email} addressd={data.address} />
    </div>
  )
}

export default Contact