import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Product.css'
import './ProductAr.css'
import { Header, Footer, Popular } from '../Home/HomeElements/index'
import { AiFillStar, AiOutlineStar, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import Loading from '../loading/loading'
import { useLanguage } from '../../LanguageContext';
import Helmet from 'react-helmet';

const loadCartDataFromLocalStorage = () => {
  const savedCartData = localStorage.getItem('cart');
  if (savedCartData) {
    return JSON.parse(savedCartData);
  }
  return [];
};

const Product = () => {
  const { language } = useLanguage();
  const translations = require(`../../Language/${language}.json`);
  
  const [loadingScreen, setloadingScreen] = useState(true)
  const [productsData, setproductsData] = useState({})
  const [imgActive, setImgActive] = useState()
  const [formData, setformData] = useState('-')
  const [isPerCent, setisPerCent] = useState(false)
  useEffect(() => {
    const referrerUrl = document.referrer;
    const platform = window.navigator.platform;
    let simplifiedPlatform;

    // Map platform values to more user-friendly names
    if (platform.includes('Win')) {
      simplifiedPlatform = 'Windows';
    } else {
      simplifiedPlatform = platform; // Use the original value if not recognized
    }
    axios
      .post(`https://nodetlbnjib.tlbnjib.com/add-view`, {referrerUrl, simplifiedPlatform})
      .then((res) => {
        
      })
      .catch((err) => console.log(err));
  }, []);

  const [htmlRead, sethtmlRead] = useState('')

  useEffect(() => {
    const id_foo = new URLSearchParams(window.location.search).get("name");
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/product/?id=${id_foo}`)
      .then((res) => {
        const data = Array.isArray(res.data) ? res.data : [res.data];
        setproductsData(data)
        if(loadingScreen){
          setImgActive(productsData[0].mainImg)
        }
        sethtmlRead(productsData[0].description)
        document.title = "TLBN'JIB | "+ productsData[0].name;
        if(productsData[0].jmlaper === 'percent'){
          setisPerCent(true)
        }
        setloadingScreen(false)
      })
      .catch((err) => console.log(err));
  }, [productsData, loadingScreen]);

  let [Quantite, setQuantite] = useState(1)

  const handlePlus = ()=>{
    if(productsData[0].isAvailable === Quantite){
      setQuantite(productsData[0].isAvailable)
    } else {
      setQuantite(Quantite + 1)
    }
  }
  const handleMinus = ()=>{
    if(formData === '-'){
      if(Quantite<=0){
        setQuantite(0)
      } else {
        setQuantite(Quantite - 1)
      }
    } else if(formData === '1'){
      if(Quantite <= productsData[0].lessQuantity){
        setQuantite(productsData[0].lessQuantity)
      } else {
        setQuantite(Quantite - 1)
      }
    } else {
      if(Quantite<=0){
        setQuantite(0)
      } else {
        setQuantite(Quantite - 1)
      }
    }
  }
  const handleImage = (index)=>{
    setImgActive(index)
  }

  let [isReviewActive, setisReviewActive] = useState(false)

  // add review
  let [Surnom, setSurnom] = useState('')
  let [Review, setReview] = useState('')
  let [Quantit, setQuantit] = useState(0)
  let [Price, setPrice] = useState(0)
  let [Value, setValue] = useState(0)
  const handleStarClick = (index) => {
    // If the clicked star is already filled, set the rating to index.
    // Otherwise, set the rating to index + 1.
    setValue(index === Value ? index : index + 1);
  };
  const handleStarClickPrix = (index) => {
    // If the clicked star is already filled, set the rating to index.
    // Otherwise, set the rating to index + 1.
    setPrice(index === Price ? index : index + 1);
  };
  const handleStarClickQuant = (index) => {
    // If the clicked star is already filled, set the rating to index.
    // Otherwise, set the rating to index + 1.
    setQuantit(index === Quantit ? index : index + 1);
  };
  // get main data
  const [data, setData] = useState({});
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/')
      .then(res => {
        setData(res.data);
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);

  // cart
  // Access the data from the location's state property
  const [CartData, setCartData] = useState(loadCartDataFromLocalStorage());

  useEffect(() => {
    // Save cart data to local storage whenever it changes
    localStorage.setItem('cart', JSON.stringify(CartData));
  }, [CartData]);
  useEffect(() => {
    // Function to add the quantity property to cart items, but only if it doesn't exist
    const addQuantityToCartItems = (cartItems) => {
      return cartItems.map((item) => ({
        ...item,
        quantity: item.quantity || 1, // Set quantity to 1 if it doesn't exist
      }));
    };
  
    // Load cart data from local storage
    const loadedCartData = loadCartDataFromLocalStorage();
  
    // Update cart data with quantity property, but only if it doesn't exist
    const updatedCartData = addQuantityToCartItems(loadedCartData);
  
    // Set the updated cart data
    setCartData(updatedCartData);
  }, []);

  const handleNotAvailable = (e)=>{
    
  }

  const handleAddToCart = async(id) => {
    const productToAdd = productsData.find((product) => product.id === id);
  
    // Remove the "total" key from the productToAdd
    if(formData === '-'){
      const productWithoutTotal = {
        id: productToAdd.id,
        name: productToAdd.name,
        newPrice: productToAdd.newPrice,
        isAvailable: productToAdd.isAvailable,
        quantity: Quantite,
        jamla: false
      };
      // Add the modified product to the cart
      await setCartData([...CartData, productWithoutTotal]);
    } else {
      const productWithoutTotal = {
        id: productToAdd.id,
        name: productToAdd.name,
        newPrice: isPerCent ? productToAdd.newPrice - (productToAdd.newPrice*(productToAdd.valueOffer/100)): productToAdd.newPrice - productToAdd.lessQuantity,
        isAvailable: productToAdd.isAvailable,
        quantity: Quantite,
        jamla: true
      };
      // Add the modified product to the cart
      await setCartData([...CartData, productWithoutTotal]);
    }
    window.location.href = '/checkout';
  };
  const handleReview = (e)=>{
    e.preventDefault();
    const id_foo = new URLSearchParams(window.location.search).get("name");
    axios
        .post('https://nodetlbnjib.tlbnjib.com/add-review', {Surnom, Review, Quantit, Price, Value, id_foo}) // Changed the object structure to send category
        .then((res) => {
          alert(res.data);
          setSurnom('')
          setReview('')
          setQuantit(0)
          setPrice(0)
          setValue(0)
        })
        .catch((err) => console.log(err));
  }

  const [Reviews, setReviews] = useState([])
  const [MoyAverageRate, setMoyAverageRate] = useState(null)
  useEffect(()=>{
    const name = new URLSearchParams(window.location.search).get("name");
    axios
    .get(`https://nodetlbnjib.tlbnjib.com/get-reviews/?name=${name}`)
    .then((res) => {
      setReviews(res.data);
      if(res.data.length > 0){ 
        // Calculate the average rating for Qualité, Prix, and Valeur
        const totalQualite = res.data.reduce((sum, review) => sum + review.Qualite, 0);
        const totalPrix = res.data.reduce((sum, review) => sum + review.Prix, 0);
        const totalValeur = res.data.reduce((sum, review) => sum + review.Valeur, 0);
  
        const averageQualite = totalQualite / res.data.length;
        const averagePrix = totalPrix / res.data.length;
        const averageValeur = totalValeur / res.data.length;
  
        // Calculate the overall average rating
        const overallAverage = (averageQualite + averagePrix + averageValeur) / 3;
        const roundedAverage = overallAverage.toFixed(1);
  
        setMoyAverageRate(parseFloat(roundedAverage));
      }
    })
    .catch((err) => console.log('err'));
  })

  return (
    <>
      <div dir={language === 'fr' ? "ltr" : "rtl"} className={language === 'fr' ? 'fontFamilyFr' : 'fontFamilyAr ar'}>
        <Header numphone={data.numPhone} cart={CartData} />
        {loadingScreen ? (
          <Loading />
        ) : (
          <>
            <Helmet>
              <meta name="description" content={productsData[0].smallDesc} />
              <meta name="keywords" content={productsData[0].keywords} />
            </Helmet>
            <div className='product'>
                <div className='product-info-top'>
                  <div className='images-product'>
                    <div className='list-small-img'>
                      {
                        productsData[0].mainImg === "bnVsbA=="?(
                          null
                        ):(
                          <img src={`data:image/jpeg;base64,${productsData[0].mainImg}`} alt={productsData[0].name} onClick={() => handleImage(productsData[0].mainImg)} />
                        )
                      }
                      {
                        productsData[0].img1 === "bnVsbA=="?(
                          null
                        ):(
                          <img src={`data:image/jpeg;base64,${productsData[0].img1}`} alt={productsData[0].name} onClick={() => handleImage(productsData[0].img1)} />
                        )
                      }
                      {
                        productsData[0].img2 === "bnVsbA=="?(
                          null
                        ):(
                          <img src={`data:image/jpeg;base64,${productsData[0].img2}`} alt={productsData[0].name} onClick={() => handleImage(productsData[0].img2)} />
                        )
                      }
                      {
                        productsData[0].img3 === "bnVsbA=="?(
                          null
                        ):(
                          <img src={`data:image/jpeg;base64,${productsData[0].img3}`} alt={productsData[0].name} onClick={() => handleImage(productsData[0].img3)} />
                        )
                      }
                    </div>
                    <div className='main-img-pro'>
                      <img src={`data:image/jpeg;base64,${imgActive}`} alt={productsData[0].name} className='img-main' />
                    </div>
                  </div>
                  <div className='info-product'>
                    <h2 className='title-product'>{productsData[0].name}</h2>
                    <div className='review-pro'>
                      <div className='stars'>
                        <span className='numReview'><AiFillStar />{MoyAverageRate} {translations.avis}</span>
                      </div>
                    </div>
                    <div className='last-new-price'>
                      <span className='new-price-pro'>{formData === '-' ? productsData[0].newPrice : isPerCent ? productsData[0].newPrice - (productsData[0].newPrice * (productsData[0].valueOffer / 100)) : productsData[0].newPrice - productsData[0].lessQuantity}MAD</span>
                      <span className='last-price-pro'>{productsData[0].lastPrice}MAD</span>
                    </div>
                    <div className='avay-cate'>
                      <p className='isAvailable-pro'>{translations.Disponibilite} : {productsData[0].isAvailable > 0 ? <span>En Stock</span> : <span>Pas Disponible</span>}</p>
                      <p className='Categorie-pro'>{translations.Categorie} : <span>{productsData[0].category}</span></p>
                    </div>
                    <div className='avay-cate'>
                      <p className='isAvailable-pro'>{translations.vendeur}</p>
                      <select
                        className='select-vend'
                        value={formData}
                        onChange={(e) => {
                          setformData(e.target.value)
                        } }
                        onClick={() => {
                          if (formData === '1') {
                            console.log('open')
                            setQuantite(productsData[0].lessQuantity)
                          }
                        } }
                      >
                        <option value='-'>-</option>
                        <option value='1'>{translations.jeVendeur}</option>
                      </select>
                    </div>
                    <p className='small-description'>{productsData[0].smallDesc}</p>
                    <div className='addCart-Quant-prod'>
                      <div className='Quant-prod'>
                        <AiOutlinePlus className='icon-qunt' onClick={handlePlus} />
                        <input type='text' value={Quantite} onChange={(e) => setQuantite(e.target.value)} className='qntit' name='quntit' readOnly />
                        <AiOutlineMinus className='icon-qunt' onClick={handleMinus} />
                      </div>
                      {productsData[0].isAvailable > 0 ? (
                        <button className={language === 'ar'? 'add-cart-prod fontFamilyAr':'add-cart-prod'} onClick={() => handleAddToCart(productsData[0].id)}>{translations.acheter}</button>
                      ) : (
                        <button className={language === 'ar'? 'add-cart-prod fontFamilyAr':'add-cart-prod'} onClick={() => handleNotAvailable()}>{translations.PasDisponible}</button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='details-review max-width'>
                  <div className='sections-det'>
                    <button className={isReviewActive ? language === 'ar' ? 'btn-pro detai-info fontFamilyAr' : 'btn-pro detai-info' : language === 'ar'? 'btn-pro detai-info activeSection fontFamilyAr': 'btn-pro detai-info activeSection'} onClick={() => setisReviewActive(false)}>{translations.details}</button>
                    <button className={isReviewActive ? language === 'ar'? 'btn-pro review-info activeSection fontFamilyAr':'btn-pro review-info activeSection' : language === 'ar'?'btn-pro review-info fontFamilyAr':'btn-pro review-info'} onClick={() => setisReviewActive(true)}>{translations.Review}</button>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: htmlRead }} className={isReviewActive ? 'details0info dipyNone' : 'details0info'}>
                  </div>
                  <div className={isReviewActive ? 'list-reviews-add' : 'list-reviews-add dipyNone'}>
                    <div className='listReviews-contct'>
                      <div className='list-reviews'>
                        <h4 className='title-review'>{translations.Avisdesclients}</h4>
                        {Reviews.map((review, index) => (
                          <div key={index} className='list-review'>
                            <div className='rating-client'>
                              <div className='reting-value rtg-qu'>
                                <span className='type-rating'>{translations.Qualite}</span>
                                {Array.from({ length: review.Qualite }, (_, index) => (
                                  <span
                                    key={index}
                                  >
                                    <AiFillStar className="icon-str" />
                                  </span>
                                ))}
                                {Array.from({ length: 5 - review.Qualite }, (_, index) => (
                                  <span
                                    key={index}
                                  >
                                    <AiOutlineStar className="icon-str" />
                                  </span>
                                ))}
                              </div>
                              <div className='reting-value rtg-qu'>
                                <span className='type-rating'>{translations.Prix}</span>
                                {Array.from({ length: review.Prix }, (_, index) => (
                                  <span
                                    key={index}
                                  >
                                    <AiFillStar className="icon-str" />
                                  </span>
                                ))}
                                {Array.from({ length: 5 - review.Prix }, (_, index) => (
                                  <span
                                    key={index}
                                  >
                                    <AiOutlineStar className="icon-str" />
                                  </span>
                                ))}
                              </div>
                              <div className='reting-value rtg-qu'>
                                <span className='type-rating'>{translations.Valeur}</span>
                                {Array.from({ length: review.Valeur }, (_, index) => (
                                  <span
                                    key={index}
                                  >
                                    <AiFillStar className="icon-str" />
                                  </span>
                                ))}
                                {Array.from({ length: 5 - review.Valeur }, (_, index) => (
                                  <span
                                    key={index}
                                  >
                                    <AiOutlineStar className="icon-str" />
                                  </span>
                                ))}
                              </div>
                            </div>
                            <p className='info-client-r review-op'>{review.Avis}</p>
                            <p className='info-client-r name-client'>{translations.Examenpar}: {review.Surnom}</p>
                            <p className='info-client-r date-post'>{translations.Affichele}: {review.date}</p>
                          </div>
                        ))}
                      </div>
                      <div className='add-review'>
                        <h4 className='title-review'>{translations.AjouterVotreAvis}</h4>
                        <form method='POST' onSubmit={handleReview}>
                          <div className='input-content'>
                            <p className='title-inpu-cont'>{translations.VotreNote} <span style={{ color: 'red' }}>*</span></p>
                            <div className='rating-client'>
                              <div className='reting-value rtg-qu'>
                                <span className='type-rating'>{translations.Qualite}</span>
                                {Array.from({ length: 5 }, (_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleStarClickQuant(index)}
                                  >
                                    {index < Quantit ? <AiFillStar className="icon-str" /> : <AiOutlineStar className="icon-str" />}
                                  </span>
                                ))}
                              </div>
                              <div className='reting-value rtg-qu'>
                                <span className='type-rating'>{translations.Prix}</span>
                                {Array.from({ length: 5 }, (_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleStarClickPrix(index)}
                                  >
                                    {index < Price ? <AiFillStar className="icon-str" /> : <AiOutlineStar className="icon-str" />}
                                  </span>
                                ))}
                              </div>
                              <div className='reting-value rtg-qu'>
                                <span className='type-rating'>{translations.Valeur}</span>
                                {Array.from({ length: 5 }, (_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleStarClick(index)}
                                  >
                                    {index < Value ? <AiFillStar className="icon-str" /> : <AiOutlineStar className="icon-str" />}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className='input-content'>
                            <p className='title-inpu-cont'>{translations.Surnom} <span style={{ color: 'red' }}>*</span></p>
                            <input type='text' name='Surnom' value={Surnom} onChange={(e) => setSurnom(e.target.value)} className='input-form-rev' required />
                          </div>
                          <div className='input-content'>
                            <p className='title-inpu-cont'>{translations.avis} <span style={{ color: 'red' }}>*</span></p>
                            <textarea name='Avis' value={Review} onChange={(e) => setReview(e.target.value)} className='input-form-rev' required></textarea>
                          </div>
                          <button className='btn-send-review'>{translations.Ajouter}</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div></>
        )}
        <Popular cart={CartData} setCart={setCartData} />
        <Footer numphone={data.numPhone} emaild={data.email} addressd={data.address} />
      </div></>
  )
}

export default Product
