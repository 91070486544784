import React, { useState, useEffect } from 'react'
import Slide from '../Slide/Slide'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import './Client.css'
import axios from 'axios'
import Loading from "../../../loading/loading"

const Client = () => {
  const [IsLoading, setIsLoading] = useState([])

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/customers')
    .then(res=>{
      if(res.data.Status === 'Success'){
        setIsLoading(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })


  const [data, setdata] = useState([])
    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-client`)
        .then((res) => {
            setdata(res.data)
        })
        .catch((err) => console.log(err));
  }, [data]);
  const [activePoup, setactivePoup] = useState(false)
  const [IdActive, setIdActive] = useState()
  const handleSubmitDelete = (e)=>{
    e.preventDefault();
    const id = IdActive
    axios.delete(`https://nodetlbnjib.tlbnjib.com/delete-client/${id}`)
    .then(res => {
      alert(res.data);
      window.location.reload();
    })
    .catch(err => console.log(err));
  }
  const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  if(IsLoading){
    return (
      <Loading />
    );
  }
  return (
    <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
              <div className='welcome-bk'>
                <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
              </div>
              <div className='user-notification-con'>
                {/*<div className='notification-cont'>
                  <div className='notification-icon'>
                    <AiOutlineBell />
                    <span className='count-nofitication'>0</span>
                  </div>
                </div>*/}
                <div className='admin-cont'>
                  <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                  {
                    LogOutBtn ? (
                      <div className='logout'>
                        <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
            <div className='list-orders' onClick={()=> setLogOutBtn(false)}>
              <h3 className='title-setction font-f'>العملاء</h3>
              <div className='orders-search'>
                <div className='form-search'>
                  {/*<form method='post'>
                    <input type='text' name='order-name' className='input-client font-f' placeholder='البحث عن العملاء' />
                    <button className='btn-search-order font-f'>بحث</button>
                  </form>*/}
                </div>
                <div className="table-container">
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th className='font-f'>الاسم الكامل</th>
                        <th className='font-f'>الهاتف</th>
                        <th className='font-f'>المدينة</th>
                        <th className='font-f'>تاريخ الإنشاء</th>
                        <th className='font-f'>اجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className='font-f'>{item.name}</td>
                          <td className='font-f'>{item.numPhone}</td>
                          <td className='font-f'>{item.city}</td>
                          <td className='font-f'>{item.date}</td>
                          <td className='font-f'>
                            <button className='btn-add-data font-f' onClick={() => {
                              setactivePoup(true)
                              setIdActive(item.id)
                            }}>
                              حذف
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='add-data' style={activePoup? {display: 'flex'}: {display: 'none'}}>
                      <div className='add-data-contxt'>
                          <form method='POST' onSubmit={handleSubmitDelete}>
                              <button type='submit' className='btn-add-data font-f'>حذف</button>
                              <p className='btn-remove-pop font-f' onClick={()=> setactivePoup(false)}>الغاء</p>
                          </form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Client