import React from 'react'
import './Features.css'
import './FeaturesAr.css'
import { AiFillCustomerService, AiOutlineSync, AiOutlineCar, AiOutlineRocket } from "react-icons/ai";
import { useLanguage } from '../../../../LanguageContext';

const Features = () => {
  const { language } = useLanguage();
  const translations = require(`../../../../Language/${language}.json`);

  const features = [
    {
      iconName: <AiFillCustomerService className='iconFeature' />,
      name: translations.Soutien,
      description: translations.SoutienDesc
    },
    {
      iconName: <AiOutlineSync className='iconFeature' />,
      name: translations.Garantie,
      description: translations.GarantieDesc
    },
    {
      iconName: <AiOutlineCar className='iconFeature' />,
      name: translations.pay,
      description: translations.payDesc
    },
    {
      iconName: <AiOutlineRocket className='iconFeature' />,
      name: translations.livr,
      description: translations.livrDesc
    },
  ]
  return (
    <div className='features'>
      <div className='features-content max-width'>
        {
          features.map((feature, index)=>(
            <div className='feature' key={index}>
              <div className='image-feature'>
                {feature.iconName}
              </div>
              <div className='content-feature'>
                <h3 className='title-feature'>{feature.name}</h3>
                <p className='desc-feature'>{feature.description}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Features
