import React, { useState, useEffect } from 'react'
import Loading from '../loading/loading'
import './Payment.css'
import './PaymentAr.css'
import { Header, Footer, Popular } from '../Home/HomeElements/index'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';

const loadCartDataFromLocalStorage = () => {
    const savedCartData = localStorage.getItem('cart');
    if (savedCartData) {
      return JSON.parse(savedCartData);
    }
    return [];
};

const Payment = () => {
  const { language } = useLanguage();
  const translations = require(`../../Language//${language}.json`);
    const [loading, setloading] = useState(true)
    const [data, setData] = useState({});
    useEffect(() => {
      axios.get('https://nodetlbnjib.tlbnjib.com/')
        .then(res => {
          setData(res.data);
          setloading(false)
        })
        .catch(error => {
          console.log('error ' + error);
        });
    }, []);

    const [CartData, setCartData] = useState(loadCartDataFromLocalStorage());
    useEffect(() => {
      // Save cart data to local storage whenever it changes
      localStorage.setItem('cart', JSON.stringify(CartData));
      console.log(CartData)
    }, [CartData]);

    //coupon data
    const location = useLocation();
    const [CouponDataClc, setCouponData] = useState(0)
    const [CouponCode, setCouponCode] = useState('')
    useEffect(() => {
        // Check if there's a state object in the location
        if (location.state && location.state.Coupon) {
            const couponData = location.state.Coupon;
            const couponCod = location.state.CouponSearch;

            // Now, you can use the couponData in your component
            // For example, you can access couponData.Coupon
            setCouponCode(couponCod)
            setCouponData(couponData)
        }
    })

    const [FullName, setFullName] = useState('')
    const [NumPhone, setNumPhone] = useState('')
    const [City, setCity] = useState('')
    const [Address, setAddress] = useState('')
    const handleSubmit = (e)=>{
        e.preventDefault();
        const totalMount = Total - (Total * (CouponDataClc/100))
        console.log(CartData)
        axios
        .post('https://nodetlbnjib.tlbnjib.com/add-order', { FullName, NumPhone, City, Address, CartData, totalMount, CouponCode })
        .then((res) => {
          localStorage.removeItem('cart');
          alert(res.data);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    }

    


    const [Total, setTotal] = useState()
    useEffect(() => {
        // Load cart data from local storage
        const loadedCartData = loadCartDataFromLocalStorage();
      
        // Set the updated cart data
        setCartData(loadedCartData);
      
        // Calculate the total amount
        const totalAmount = calculateTotalAmount(loadedCartData);
        setTotal(totalAmount);
        console.log(Total)
    }, []);
    const calculateTotalAmount = (cartData) => {
        return cartData.reduce((total, item) => {
          const itemTotal = item.newPrice * item.quantity;
          return total + itemTotal;
        }, 0);
    };
  return (
    <div dir={language === 'fr' ? "ltr" : "rtl"} className={language==='fr'? 'fontFamilyFr': 'fontFamilyAr ar'}>
      {
        loading ? (
          <Loading />
        )
        : (
            <>
                <Header numphone={data.numPhone} cart={CartData} />
                <div className='complet-order'>
                    <h3 className='title-order'>{translations.completeOrder}</h3>
                    <div className='form-complete-order'>
                        <form method='POST' onSubmit={handleSubmit}>
                            <div className='input-two-ord'>
                                <input type='text' value={FullName} onChange={(e)=> setFullName(e.target.value)} name='nomPren' className={language === 'ar' ? 'input-form-order fontFamilyAr' : 'input-form-order'} placeholder={translations.formCompleteOrder} required/>
                                <input type='text' value={NumPhone} onChange={(e)=> setNumPhone(e.target.value)} name='numPhone' className={language === 'ar' ? 'input-form-order fontFamilyAr' : 'input-form-order'} placeholder={translations.formCompleteOrder1} required/>
                            </div>
                            <div className='input-two-ord'>
                                <input type='text' name='city' value={City} onChange={(e)=> setCity(e.target.value)} className={language === 'ar' ? 'input-form-order fontFamilyAr' : 'input-form-order'} placeholder={translations.formCompleteOrder2} required/>
                                <input type='text' name='Address' value={Address} onChange={(e)=> setAddress(e.target.value)} className={language === 'ar' ? 'input-form-order fontFamilyAr' : 'input-form-order'} placeholder={translations.formCompleteOrder3} required/>
                            </div>
                            <div className='total-pay'>
                                <p className='tit-total-p'>{translations.Order4}:</p>
                                <p className='pay-tot'>{Total - (Total * (CouponDataClc/100))} MAD</p>
                            </div>
                            <button className={language === 'ar' ? 'btn-send-order fontFamilyAr' : 'btn-send-order'}>{translations.formCompleteOrder4}</button>
                        </form>
                    </div>
                </div>
                <Popular cart={CartData} setCart={setCartData} />
                <Footer numphone={data.numPhone} emaild={data.email} addressd={data.address} />
            </>
        )
      }
    </div>
  )
}

export default Payment
