import React, {useState, useEffect} from 'react'
import { Header, SliderShow, Features, Popular, NewProducts, Footer } from './HomeElements/index'
import axios from 'axios';
import Loading from '../loading/loading'
import { useLanguage } from '../../LanguageContext';

const Home = () => {
  const [data, setData] = useState({});
  const [LoadingScreen, setLoading] = useState(true)
  const { language } = useLanguage();
  const translations = require(`../../Language/${language}.json`);
  useEffect(() => {
    axios.get(`https://nodetlbnjib.tlbnjib.com/`)
      .then(res => {
        setData(res.data);
        setLoading(false)
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);
  useEffect(() => {
    const referrerUrl = document.referrer;
    const platform = window.navigator.platform;
    let simplifiedPlatform;

    // Map platform values to more user-friendly names
    if (platform.includes('Win')) {
      simplifiedPlatform = 'Windows';
    } else {
      simplifiedPlatform = platform; // Use the original value if not recognized
    }
    axios
      .post(`https://nodetlbnjib.tlbnjib.com/add-view`, {referrerUrl, simplifiedPlatform})
      .then((res) => {
        
      })
      .catch((err) => console.log(err));
  }, []);
  // cart
  const [Cart, setCart] = useState([])
  // Save cart data to local storage when it changes
  useEffect(() => {
    // Retrieve cart data from local storage when the component mounts
    const savedCart = localStorage.getItem('cart');
  
    if (savedCart) {
      // If cart data exists in local storage, set the Cart state with the saved data
      setCart(JSON.parse(savedCart));
    } else {
      // If no cart data exists in local storage, initialize Cart with an empty array
      setCart([]);
    }
  }, []);
  
  // Update local storage whenever the Cart state changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(Cart));
  }, [Cart]);
  if(LoadingScreen){
    return ( <Loading /> )
  }
  return (
    <div className={language==='fr'? 'MainHome fontFamilyFr': 'MainHome fontFamilyAr ar'} dir={language === 'fr' ? "ltr" : "rtl"}>
      <Header numphone={data.numPhone} cart={Cart} />
      <SliderShow />
      <Features />
      <Popular cart={Cart} setCart={setCart} />
      <NewProducts cart={Cart} setCart={setCart} />
      <Footer numphone={data.numPhone} facebook={data.facebook} instagram={data.instagram} whatsapp={data.whatsapp} emaild={data.email} addressd={data.address}/>
    </div>
  )
}

export default Home
