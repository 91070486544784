import React, { useState, useEffect } from 'react';
import { BiSolidDiscount, BiCartAdd, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AiOutlineRight } from "react-icons/ai";
import axios from 'axios';
import './NewProducts.css';
import './NewProductsAr.css';
import { useLanguage } from '../../../../LanguageContext';
import Loading from '../../../loading/loading'

const NewProducts = (props) => {
  const { language } = useLanguage();
  const translations = require(`../../../../Language/${language}.json`);
  const { cart, setCart } = props;
  const [newProducts, setNewProducts] = useState([]);
  const [LoadingScreen, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get('https://nodetlbnjib.tlbnjib.com/products-limit')
      .then((res) => {
        setNewProducts(res.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  }, []);

  const handleAddToCart = (id) => {
    const productToAdd = newProducts.find((product) => product.id === id);
  
    // Remove the "total" key from the productToAdd
    const productWithoutTotal = {
      id: productToAdd.id,
      name: productToAdd.name,
      newPrice: productToAdd.newPrice,
      isAvailable: productToAdd.isAvailable,
      quantity: 1,
      jamla: false
    };

    // Add the modified product to the cart
    setCart([...cart, productWithoutTotal]);
  }
  if(LoadingScreen){
    return ( <Loading /> )
  }
  return (
    <div className="new-products">
      <div className="newProducts max-width-pop">
        <div className="newProductsTit">
          <h2 className="title-prod-new">{translations.newPro}</h2>
          <a href="/products" className="voir-more-pop">
            {translations.voirPlus}<AiOutlineRight className="icon-pop-right" />
          </a>
        </div>
        <div className="list-new-prod">
          {newProducts.map((newProduct, index) => (
            newProduct.isAvailable > 0?(
              <div className="product-flash" key={newProduct.id}>
                {
                  newProduct.lastPrice>0?(
                    <div className='promo'>
                      <p className='p-prpmo'>PROMO</p>
                    </div>
                  ):(
                    null
                  )
                }
                <div className="img-produ-flash">
                  <img
                    src={`data:image/jpeg;base64,${newProduct.mainImg}`}
                    alt={newProduct.name}
                    className="img-flash"
                  />
                </div>
                <div className="info-prod-flash">
                  <a
                    href={`/product/?name=${newProduct.url}`}
                    className="title-prod-f"
                  >
                    {newProduct.name}
                  </a>
                  <div className="price-cart-pop">
                    <div className="price-pop">
                      <span className="current-price-pop">
                        {newProduct.newPrice}MAD
                      </span>
                      {
                        newProduct.lastPrice > 0 ? (
                          <span className="last-price-pop">
                            {newProduct.lastPrice}MAD
                          </span>
                        ) : (
                          null
                        )
                      }
                    </div>
                    <div className="addToCart-pop">
                      <BiCartAdd
                        className="icon-pop-cart"
                        onClick={() => handleAddToCart(newProduct.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="product-flash" style={{filter: 'opacity(0.5)'}} key={newProduct.id}>
                <div className='pas-dis'>
                  <p className='p-pas'>{translations.PasDisponible}</p>
                </div>
                <div className="img-produ-flash">
                  <img
                    src={`data:image/jpeg;base64,${newProduct.mainImg}`}
                    alt={newProduct.name}
                    className="img-flash"
                  />
                </div>
                <div className="info-prod-flash">
                  <a
                    href={`#`}
                    className="title-prod-f"
                  >
                    {newProduct.name}
                  </a>
                  <div className="price-cart-pop">
                    <div className="price-pop">
                      <span className="current-price-pop">
                        {newProduct.newPrice}MAD
                      </span>
                      <span className="last-price-pop">
                        {newProduct.lastPrice}MAD
                      </span>
                    </div>
                    <div className="addToCart-pop">
                      <BiCartAdd
                        className="icon-pop-cart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewProducts;