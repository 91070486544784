import './App.css';
//import Navigation from './components/navigation/Navigation';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "reactstrap";
import Home from './components/pages/Home/Home';
import Product from './components/pages/product/Product';
import Login from './components/pages/Dashboard/Login/Login'
import HomeDashboard from './components/pages/Dashboard/Sections/Home/Home'
import Orders from './components/pages/Dashboard/Sections/Orders/Orders'
import Coupon from './components/pages/Dashboard/Sections/Coupon/Coupon';
import Client from './components/pages/Dashboard/Sections/Client/Client';
import Insights from './components/pages/Dashboard/Sections/Insights/Insights';
import Settings from './components/pages/Dashboard/Sections/Settings/Settings';
import Listproducts from './components/pages/Dashboard/Sections/products/Listproducts/Listproducts';
import Newproduct from './components/pages/Dashboard/Sections/products/Newproduct/Newproduct';
import Category from './components/pages/Dashboard/Sections/products/Category/Category';
import Checkout from './components/pages/Checkout/Checkout';
import Payment from './components/pages/payment/Payment';
import ListProducts from './components/pages/listProducts/listProducts';
import Contact from './components/pages/subPages/Contact/Contact';
import Message from './components/pages/Dashboard/Sections/Message/Message';
import PlusVent from './components/pages/PlusVent/PlusVent';
import SommesNous from './components/pages/subPages/SommesNous/SommesNous';
import Discount from './components/pages/subPages/Discount/Discount';
import Policy from './components/pages/subPages/Policy/Policy';
import ConditionsUtilisation from './components/pages/subPages/ConditionsUtilisation/ConditionsUtilisation';
import MethodPay from './components/pages/subPages/MethodPay/MethodPay';
import InfoLivration from './components/pages/subPages/InfoLivration/InfoLivration';
import LieuxEx from './components/pages/subPages/LieuxEx/LieuxEx';

function App() {
  return (
    <>
      <Router>
        <Container>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/product' element={<Product />} />
            <Route path='/products' element={<ListProducts />} />
            <Route path='/ventes-flash' element={<PlusVent />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/checkout/information' element={<Payment />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/qui-sommes-nous' element={<SommesNous />} />
            <Route path='/discount' element={<Discount />} />
            <Route path='/politique-de-confidentialité' element={<Policy />} />
            <Route path='/conditions-utilisation' element={<ConditionsUtilisation />} />
            <Route path='/methodes-de-payement' element={<MethodPay />} />
            <Route path='/informations-de-livraison' element={<InfoLivration />} />
            <Route path='/lieux-vers-lesquels-nous-expedions' element={<LieuxEx />} />

            <Route path='/admin-area/login' element={<Login />} />
            <Route path='/admin-area/dashboard' element={<HomeDashboard />} />
            <Route path='/admin-area/orders' element={<Orders />} />
            <Route path='/admin-area/coupon' element={<Coupon />} />
            <Route path='/admin-area/customers' element={<Client />} />
            <Route path='/admin-area/insight' element={<Insights />} />
            <Route path='/admin-area/settings' element={<Settings />} />
            <Route path='/admin-area/message' element={<Message />} />
            <Route path='/admin-area/products' element={<Listproducts />} />
            <Route path='/admin-area/products/create' element={<Newproduct />} />
            <Route path='/admin-area/products/category' element={<Category />} />
          </Routes>
        </Container>
      </Router>
    </>
  );
}

export default App;
