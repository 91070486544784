import React, { useState } from 'react'
import { 
    AiOutlineMenu,
    AiOutlineHome,
    AiOutlineAntDesign,
    AiOutlineInbox,
    AiOutlineTag,
    AiOutlineUser,
    AiOutlineBarChart,
    AiOutlineSetting,
    AiOutlineMessage
} from "react-icons/ai";
import './Slide.css'
const Slide = () => {
    const listSlides = [
        {
            id: 0,
            name: 'لوحة التحكم',
            icon: <AiOutlineHome />,
            link: '/admin-area/dashboard'
        },
        {
            id: 1,
            name: 'الطلبيات',
            icon: <AiOutlineAntDesign />,
            link: '/admin-area/orders'
        },
        {
            id: 2,
            name: 'المنتجات',
            icon: <AiOutlineInbox />,
            link: '#'
        }, 
        {
            id: 3,
            name: 'كوبون',
            icon: <AiOutlineTag />,
            link: '/admin-area/coupon'
        }, 
        {
            id: 4,
            name: 'العملاء',
            icon: <AiOutlineUser />,
            link: '/admin-area/customers'
        }, 
        {
            id: 5,
            name: 'إحصائيات',
            icon: <AiOutlineBarChart />,
            link: '/admin-area/insight'
        },
        {
            id: 6,
            name: 'الرسائل',
            icon: <AiOutlineMessage />,
            link: '/admin-area/message'
        }, 
        
    ]
    const [closeAside, setCloseAside] = useState(true)
    const handleToggle = ()=>{
        setCloseAside(!closeAside)
    }
    const [OrderActive, setOrderActive] = useState(false)
  return (
    <aside className={closeAside ? 'slide-menu-close' : 'slide-menu'}>
      <div className='slide-menu-cont'>
        <h4 className={closeAside ? 'title-website sec-menu-Active' : 'title-website'}><AiOutlineMenu className='main-ico-menu' onClick={handleToggle} /><span className={closeAside ? 'dispN' : ''}>TLBN'JIB</span></h4>
        <div className='menu-principal'>
            <ul className='list-menu'>
                {
                    listSlides.map((data, index)=>(
                        data.name === 'المنتجات' ? (
                            <>
                                <li key={index + 1} onClick={()=> setOrderActive(!OrderActive)} style={OrderActive? {backgroundColor: '#ffd259'} : {display: 'flex'}} className={closeAside ? 'sec-menu sec-menu-Active' : 'sec-menu'}>{data.icon} <span className={closeAside ? 'dispN' : ''}>المنتجات</span></li>
                                <div className={OrderActive? 'ordActive': 'dispN'}>
                                    <a href='/admin-area/products' style={{color: 'white'}}><li key={index + 1} className={closeAside ? 'sec-menu sec-menu-Active' : 'sec-menu colr-menu'}><span className={closeAside ? 'dispN' : ''}>جميع المنتجات</span></li></a>
                                    <a href='/admin-area/products/create' style={{color: 'white'}}><li key={index + 1} className={closeAside ? 'sec-menu sec-menu-Active' : 'sec-menu colr-menu'}><span className={closeAside ? 'dispN' : ''}>منتج جديد</span></li></a>
                                    <a href='/admin-area/products/category' style={{color: 'white'}}><li key={index + 1} className={closeAside ? 'sec-menu sec-menu-Active' : 'sec-menu colr-menu'}><span className={closeAside ? 'dispN' : ''}>التصنيفات</span></li></a>
                                </div>
                            </>
                        ) : (
                            <a href={data.link} key={index} style={{color: 'white'}}>
                                <li key={index + 1} className={closeAside ? 'sec-menu sec-menu-Active' : 'sec-menu'}>{data.icon} <span className={closeAside ? 'dispN' : ''}>{data.name}</span></li>
                            </a>
                        )
                    ))
                }
            </ul>
            <div className='settings'>
                <a href='/admin-area/settings' className={closeAside ? 'setting-con sec-menu-Active' : 'setting-con'}><AiOutlineSetting /> <span className={closeAside ? 'dispN' : ''}>الاعدادات</span></a>
            </div>
        </div>
      </div>
    </aside>
  )
}

export default Slide
