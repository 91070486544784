import React, {useState, useEffect} from 'react'
import { useLanguage } from '../../../LanguageContext';
import axios from 'axios'
import { Header, Footer, Popular } from '../../Home/HomeElements/index'
const loadCartDataFromLocalStorage = () => {
    const savedCartData = localStorage.getItem('cart');
    if (savedCartData) {
      return JSON.parse(savedCartData);
    }
    return [];
};

const ConditionsUtilisation = () => {
  const { language } = useLanguage();
  const translations = require(`../../../Language/${language}.json`);
  // get main data
  const [data, setData] = useState({});
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/')
      .then(res => {
        setData(res.data);
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);
  // cart
  // Access the data from the location's state property
  const [CartData, setCartData] = useState(loadCartDataFromLocalStorage());

  useEffect(() => {
    // Save cart data to local storage whenever it changes
    localStorage.setItem('cart', JSON.stringify(CartData));
  }, [CartData]);
  useEffect(() => {
    // Function to add the quantity property to cart items, but only if it doesn't exist
    const addQuantityToCartItems = (cartItems) => {
      return cartItems.map((item) => ({
        ...item,
        quantity: item.quantity || 1, // Set quantity to 1 if it doesn't exist
      }));
    };
  
    // Load cart data from local storage
    const loadedCartData = loadCartDataFromLocalStorage();
  
    // Update cart data with quantity property, but only if it doesn't exist
    const updatedCartData = addQuantityToCartItems(loadedCartData);
  
    // Set the updated cart data
    setCartData(updatedCartData);
  }, []); 
  useEffect(() => {
    document.title = translations.footer9 + " | TLBN'JIB"
  })
  return (
    <div dir={language === 'fr' ? "ltr" : "rtl"} className={language==='fr'? 'fontFamilyFr': 'fontFamilyAr ar'}>
      <Header numphone={data.numPhone} cart={CartData} />
      <div className='main-info'>
        <h1>{translations.footer9}</h1>
        <div dangerouslySetInnerHTML={{ __html: translations.footer9Content }} />
      </div>
      <Footer numphone={data.numPhone} emaild={data.email} addressd={data.address} />      
    </div>
  )
}

export default ConditionsUtilisation