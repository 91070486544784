import React, { useState, useEffect } from 'react';
import { BiCartAdd, BiSolidDiscount } from "react-icons/bi";
import { AiOutlineRight } from "react-icons/ai";
import axios from 'axios';
import './PlusVent.css';
import { Header, Footer, Popular } from '../Home/HomeElements/index';
import { useLanguage } from '../../LanguageContext';
import Loading from '../loading/loading';

const loadCartDataFromLocalStorage = () => {
    const savedCartData = localStorage.getItem('cart');
    if (savedCartData) {
      return JSON.parse(savedCartData);
    }
    return [];
};  

const PlusVent = () => {
  const { language } = useLanguage();
  const translations = require(`../../Language//${language}.json`);
  // get main data
  const [Data, setData] = useState({});
  const [LoadingScree, setLoadingScree] = useState(true)
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/')
      .then(res => {
        setData(res.data);
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);

  const [newProducts, setNewProducts] = useState([]);

    useEffect(() => {
        axios
          .get('https://nodetlbnjib.tlbnjib.com/products-plus-vent')
          .then((res) => {
            setNewProducts(res.data);
            setLoadingScree(false)
          })
          .catch((error) => {
            console.log('error ' + error);
          });
    }, []);


  //add view
  useEffect(() => {
    const referrerUrl = document.referrer;
    const platform = window.navigator.platform;
    let simplifiedPlatform;

    // Map platform values to more user-friendly names
    if (platform.includes('Win')) {
      simplifiedPlatform = 'Windows';
    } else {
      simplifiedPlatform = platform; // Use the original value if not recognized
    }
    axios
      .post(`https://nodetlbnjib.tlbnjib.com/add-view`, {referrerUrl, simplifiedPlatform})
      .then((res) => {
        
      })
      .catch((err) => console.log(err));
  }, []);

  // Access the data from the location's state property
  const [CartData, setCartData] = useState(loadCartDataFromLocalStorage());

  useEffect(() => {
    // Save cart data to local storage whenever it changes
    localStorage.setItem('cart', JSON.stringify(CartData));
    console.log(CartData)
  }, [CartData]);

  const handleAddToCart = (id) => {
    const productToAdd = newProducts.find((product) => product.id === id);
  
    // Remove the "total" key from the productToAdd
    const productWithoutTotal = {
      id: productToAdd.id,
      name: productToAdd.name,
      newPrice: productToAdd.newPrice,
      isAvailable: productToAdd.isAvailable,
      quantity: 1
    };

    // Add the modified product to the cart
    setCartData([...CartData, productWithoutTotal]);
  }
  if(LoadingScree){
    return (<Loading />)
  }

  return (
    <div dir={language === 'fr' ? "ltr" : "rtl"} className={language==='fr'? 'fontFamilyFr': 'fontFamilyAr ar'}>
        <Header numphone={Data.numPhone} cart={CartData} />
        <div className="new-products">
          <div className="newProducts max-width-pop">
            <div className="newProductsTit">
            <h2 className='title-popular'><BiSolidDiscount className='icon-pop' />{translations.venteFlash}</h2>
            </div>
            <div className="list-new-prod">
            {
              newProducts.length > 0 ? (
                newProducts.map((newProduct, index) => (
                  newProduct.isAvailable > 0?(
                    <div className="product-flash" key={newProduct.id}>
                      {
                        newProduct.lastPrice>0?(
                          <div className='promo'>
                            <p className='p-prpmo'>PROMO</p>
                          </div>
                        ):(
                          null
                        )
                      }
                      <div className="img-produ-flash">
                        <img
                          src={`data:image/jpeg;base64,${newProduct.mainImg}`}
                          alt={newProduct.name}
                          className="img-flash"
                        />
                      </div>
                      <div className="info-prod-flash">
                        <a
                          href={`/product/?name=${newProduct.url}`}
                          className="title-prod-f"
                        >
                          {newProduct.name}
                        </a>
                        <div className="price-cart-pop">
                          <div className="price-pop">
                            <span className="current-price-pop">
                              {newProduct.newPrice}MAD
                            </span>
                            {
                              newProduct.lastPrice > 0 ? (
                                <span className="last-price-pop">
                                  {newProduct.lastPrice}MAD
                                </span>
                              ) : (
                                null
                              )
                            }
                          </div>
                          <div className="addToCart-pop">
                            <BiCartAdd
                              className="icon-pop-cart"
                              onClick={() => handleAddToCart(newProduct.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="product-flash" style={{filter: 'opacity(0.5)'}} key={newProduct.id}>
                      <div className='pas-dis'>
                        <p className='p-pas'>{translations.PasDisponible}</p>
                      </div>
                      <div className="img-produ-flash">
                        <img
                          src={`data:image/jpeg;base64,${newProduct.mainImg}`}
                          alt={newProduct.name}
                          className="img-flash"
                        />
                      </div>
                      <div className="info-prod-flash">
                        <a
                          href={`#`}
                          className="title-prod-f"
                        >
                          {newProduct.name}
                        </a>
                        <div className="price-cart-pop">
                          <div className="price-pop">
                            <span className="current-price-pop">
                              {newProduct.newPrice}MAD
                            </span>
                            <span className="last-price-pop">
                              {newProduct.lastPrice}MAD
                            </span>
                          </div>
                          <div className="addToCart-pop">
                            <BiCartAdd
                              className="icon-pop-cart"
                              onClick={() => handleAddToCart(newProduct.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ))
              ) : (
                <p className='notAvailable'>{language === 'fr'? 'Les produits de cette catégorie sont actuellement indisponibles': 'المنتجات من هذه الفئة غير متوفر حاليا'}</p>
              )
            }
            </div>
          </div>
        </div>
        <Footer numphone={Data.numPhone} emaild={Data.email} addressd={Data.address} />
    </div>
  );
};

export default PlusVent;