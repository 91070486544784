import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import Slide from '../../Slide/Slide'
import './Newproduct.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../../loading/loading'

const Newproduct = () => {
  const [isLoading, setLoading] = useState(true)

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/products/create')
    .then(res=>{
      if(res.data.Status === 'Success'){
        setLoading(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })

    // input target
    const [formData, setFormData] = useState({
        productName: '',
        productUrl: '',
        availableQuantity: '',
        description: '',
        category: '',
        price: '',
        comparisonPrice: '',
        firstImages: null,
        secondImages: null,
        thirdImages: null,
        fourthImages: null,
        discountType: 'percent',
        discountValue: '',
        minQuantity: '',
        keywords: '',
        productDescription: '',
    });

    // get categories
    const [Categories, setCategories] = useState([])
    const [loading, setloading] = useState(true)
    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-categoris`)
        .then((res) => {
          setCategories(res.data)
          setloading(false)
        })
        .catch((err) => console.log(err));
    }, [Categories]);

    //submit form
    const handleSubmit = (e) => {
      e.preventDefault();
    
      const reader = new FileReader();
      const readerLongImage = new FileReader();
    
      reader.onload = () => {
        // The FileReader object has finished reading the first Blob object.
        // Now you can read the second Blob object.
    
        readerLongImage.onload = () => {
          // The FileReader for longImageBuffer is also done.
          const longImageBuffer = readerLongImage.result;
    
          const data = new FormData();
          data.append('firstImages', new Blob([longImageBuffer], { type: formData.firstImages.type }));
          data.append('secondImages', new Blob([formData.secondImages]));
          data.append('thirdImages', new Blob([formData.thirdImages]));
          data.append('fourthImages', new Blob([formData.fourthImages]));
          data.append('productName', formData.productName);
          data.append('productUrl', formData.productUrl);
          data.append('availableQuantity', formData.availableQuantity);
          data.append('description', formData.description);
          data.append('category', formData.category);
          data.append('price', formData.price);
          data.append('comparisonPrice', formData.comparisonPrice);
          data.append('discountType', formData.discountType);
          data.append('discountValue', formData.discountValue);
          data.append('minQuantity', formData.minQuantity);
          data.append('keywords', formData.keywords);
          data.append('productDescription', formData.productDescription);
    
          axios.post('https://nodetlbnjib.tlbnjib.com/add-product', data)
            .then((res) => {
              alert(res.data);
              window.location.reload();
            })
            .catch((err) => console.log(err));
        };
    
        // Read the longImageBuffer Blob first.
        readerLongImage.readAsArrayBuffer(new Blob([formData.firstImages]));
      };
    
      // Read the first Blob object.
      reader.readAsArrayBuffer(new Blob([formData.firstImages]));
    };

    const id_foo = new URLSearchParams(window.location.search).get("id");
    useEffect(() => {
      if (id_foo) {
        axios
          .get(`https://nodetlbnjib.tlbnjib.com/get-exact-product/?id=${id_foo}`)
          .then((res) => {
            const productData = res.data[0];
            // Update formData with the product data
            setFormData({
              ...formData,
              productName: productData.name,
              price: productData.newPrice,
              comparisonPrice: productData.lastPrice,
              category: productData.category,
              description: productData.description,
              availableQuantity: productData.isAvailable,
              discountType: productData.jmlaper,
              discountValue: productData.valueOffer,
              minQuantity: productData.lessQuantity,
            });
    
         })
          .catch((err) => console.log(err));
      }
    }, [id_foo]);

    const handleSubmitUpdate = (e)=>{
      e.preventDefault();
      axios
        .post('https://nodetlbnjib.tlbnjib.com/update-product', {formData, id_foo})
        .then((res) => {
          alert(res.data);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    }
    const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  if(isLoading){
    return (
      <Loading />
    );
  }
    
  return (
    <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
              <div className='welcome-bk'>
                <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
              </div>
              <div className='user-notification-con'>
                {/*<div className='notification-cont'>
                  <div className='notification-icon'>
                    <AiOutlineBell />
                    <span className='count-nofitication'>0</span>
                  </div>
                </div>*/}
                <div className='admin-cont'>
                  <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                  {
                    LogOutBtn ? (
                      <div className='logout'>
                        <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
            <div className='create-product' onClick={()=> setLogOutBtn(false)}>
                {
                  id_foo ? (
                    <>
                    <h3 className='title-product font-f'>تعديل منتج</h3>
                <form method='POST' onSubmit={handleSubmitUpdate} encType="multipart/form-data">
                    <div className='maininfo-pr'>
                      <div className='input-cotnt'>
                        <input
                          type='text'
                          className='input-form-pr font-f'
                          name='productName'
                          placeholder='اسم المنتج'
                          required
                          value={formData.productName}
                          onChange={(e) => setFormData({...formData, productName: e.target.value})}
                        />
                      </div>
                      <div className='input-cotnt'>
                        <input
                          type='text'
                          className='input-form-pr font-f'
                          name='availableQuantity'
                          placeholder='الكمية المتوفرة'
                          required
                          value={formData.availableQuantity}
                          onChange={(e) => setFormData({...formData, availableQuantity: e.target.value})}
                        />
                      </div>
                      <div className='input-cotnt'>
                        <ReactQuill
                          theme="snow"
                          value={formData.description}
                          className='font-f text-edter'
                          onChange={(value) => setFormData({ ...formData, description: value })}
                        />
                      </div>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>التصنيف الخاص بالمنتج</h4>
                      <select
                        name='category'
                        className='font-f input-form-pr'
                        required
                        value={formData.category}
                        onChange={(e) => setFormData({...formData, category: e.target.value})}
                      >
                        <option value=''></option>
                        {
                          Categories.map((category, index) => (
                            <option key={index} value={category.category}>{category.category}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>التسعير</h4>
                      <div className='inpt-contxt'>
                        <input
                          type='number'
                          className='input-form-pr two-avai-frm font-f'
                          name='price'
                          placeholder='السعر'
                          required
                          value={formData.price}
                          onChange={(e) => setFormData({...formData, price: e.target.value})}
                        />
                        <input
                          type='number'
                          className='input-form-pr two-avai-frm font-f'
                          name='comparisonPrice'
                          placeholder='مقارنة السعر (اختياري)'
                          value={formData.comparisonPrice}
                          onChange={(e) => setFormData({...formData, comparisonPrice: e.target.value})}
                        />
                      </div>
                    </div>
                    
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>لمن يريد شراء كمية كبيرة</h4>
                      <div className='inpt-contxt'>
                        <select
                          name='discountType'
                          className='font-f input-form-pr'
                          required
                          value={formData.discountType}
                          onChange={(e) => setFormData({...formData, discountType: e.target.value})}
                        >
                          <option className='font-f' value='percent'>تحفيض بالنسبة المئوية</option>
                          <option className='font-f' value='mad'>تحفيض ثابت بالدرهم</option>
                        </select>
                        <input
                          type='number'
                          className='input-form-pr thr-avai-frm font-f'
                          name='discountValue'
                          placeholder='قيمة التحفيض'
                          required
                          value={formData.discountValue}
                          onChange={(e) => setFormData({...formData, discountValue: e.target.value})}
                        />
                        <input
                          type='number'
                          className='input-form-pr thr-avai-frm font-f'
                          name='minQuantity'
                          placeholder='اقل كمية يمكن طلبها'
                          required
                          value={formData.minQuantity}
                          onChange={(e) => setFormData({...formData, minQuantity: e.target.value})}
                        />
                      </div>
                    </div>
                    <button type='submit' className='font-f btn-pub-pro' name='add-product'>
                      حفظ
                    </button>
                </form>
                    </>
                  ) : (
                    <>
                    <h3 className='title-product font-f'>إنشاء منتج</h3>
                <form method='POST' onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className='maininfo-pr'>
                      <div className='input-cotnt'>
                        <input
                          type='text'
                          className='input-form-pr font-f'
                          name='productName'
                          placeholder='اسم المنتج'
                          required
                          value={formData.productName}
                          onChange={(e) => setFormData({...formData, productName: e.target.value})}
                        />
                      </div>
                      <div className='input-cotnt'>
                        <input
                          type='text'
                          className='input-form-pr font-f'
                          name='productUrl'
                          placeholder='محتوى الرابط مثال (اسم-المنتج)'
                          required
                          value={formData.productUrl}
                          onChange={(e) => setFormData({...formData, productUrl: e.target.value})}
                        />
                      </div>
                      <div className='input-cotnt'>
                        <input
                          type='text'
                          className='input-form-pr font-f'
                          name='availableQuantity'
                          placeholder='الكمية المتوفرة'
                          required
                          value={formData.availableQuantity}
                          onChange={(e) => setFormData({...formData, availableQuantity: e.target.value})}
                        />
                      </div>
                      <div className='input-cotnt'>
                        <ReactQuill
                          theme="snow"
                          value={formData.description}
                          className='font-f text-edter'
                          onChange={(value) => setFormData({ ...formData, description: value })}
                        />
                      </div>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>التصنيف الخاص بالمنتج</h4>
                      <select
                        name='category'
                        className='font-f input-form-pr'
                        required
                        value={formData.category}
                        onChange={(e) => setFormData({...formData, category: e.target.value})}
                      >
                        <option value=''></option>
                        {
                          Categories.map((category, index) => (
                            <option key={index} value={category.category}>{category.category}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>التسعير</h4>
                      <div className='inpt-contxt'>
                        <input
                          type='number'
                          className='input-form-pr two-avai-frm font-f'
                          name='price'
                          placeholder='السعر'
                          required
                          value={formData.price}
                          onChange={(e) => setFormData({...formData, price: e.target.value})}
                        />
                        <input
                          type='number'
                          className='input-form-pr two-avai-frm font-f'
                          name='comparisonPrice'
                          placeholder='مقارنة السعر (اختياري)'
                          value={formData.comparisonPrice}
                          onChange={(e) => setFormData({...formData, comparisonPrice: e.target.value})}
                        />
                      </div>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>الصور</h4>
                      <div className='inpt-contxt'>
                        <div className='upload-img'>
                          <h6 className='tit-sec-form font-f'>الصور الأولى</h6>
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="firstImages"
                            onChange={(e) => setFormData({...formData, firstImages: e.target.files[0]})}
                          />
                        </div>
                        <div className='upload-img'>
                          <h6 className='tit-sec-form font-f'>الصور الثانية</h6>
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="secondImages"
                            onChange={(e) => setFormData({...formData, secondImages: e.target.files[0]})}
                          />
                        </div>
                        <div className='upload-img'>
                          <h6 className='tit-sec-form font-f'>الصور الثالثة</h6>
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="thirdImages"
                            onChange={(e) => setFormData({...formData, thirdImages: e.target.files[0]})}
                          />
                        </div>
                        <div className='upload-img'>
                          <h6 className='tit-sec-form font-f'>الصور الرابعة</h6>
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="fourthImages"
                            onChange={(e) => setFormData({...formData, fourthImages: e.target.files[0]})}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>لمن يريد شراء كمية كبيرة</h4>
                      <div className='inpt-contxt'>
                        <select
                          name='discountType'
                          className='font-f input-form-pr'
                          required
                          value={formData.discountType}
                          onChange={(e) => setFormData({...formData, discountType: e.target.value})}
                        >
                          <option className='font-f' value='percent'>تحفيض بالنسبة المئوية</option>
                          <option className='font-f' value='mad'>تحفيض ثابت بالدرهم</option>
                        </select>
                        <input
                          type='number'
                          className='input-form-pr thr-avai-frm font-f'
                          name='discountValue'
                          placeholder='قيمة التحفيض'
                          required
                          value={formData.discountValue}
                          onChange={(e) => setFormData({...formData, discountValue: e.target.value})}
                        />
                        <input
                          type='number'
                          className='input-form-pr thr-avai-frm font-f'
                          name='minQuantity'
                          placeholder='اقل كمية يمكن طلبها'
                          required
                          value={formData.minQuantity}
                          onChange={(e) => setFormData({...formData, minQuantity: e.target.value})}
                        />
                      </div>
                    </div>
                    <div className='input-cotnt maininfo-pr dspFlex'>
                      <h4 className='tit-sec-form font-f'>تحسين محركات البحث</h4>
                      <div className='inpt-contxt'>
                        <input
                          type='text'
                          className='input-form-pr two-avai-frm font-f'
                          name='keywords'
                          placeholder='الكلمات المفتاحية'
                          required
                          value={formData.keywords}
                          onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                        />
                        <input
                          type='text'
                          className='input-form-pr two-avai-frm font-f'
                          name='productDescription'
                          placeholder='الوصف الخاص بالمنتج'
                          value={formData.productDescription}
                          onChange={(e) => setFormData({...formData, productDescription: e.target.value})}
                        />
                      </div>
                    </div>
                    <button type='submit' className='font-f btn-pub-pro' name='add-product'>
                      حفظ
                    </button>
                </form>
                    </>
                  )
                }
                
            </div>
        </div>
    </div>
  )
}

export default Newproduct
