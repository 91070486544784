import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from 'axios'
import "./Login.css";

function Login() {
  // React States
  const [Username, setUsername] = useState('')
  const [Password, setPassword] = useState('')
  const [BtnLogin, setBtnLogin] = useState(false)
  axios.defaults.withCredentials = true;
  const handleSubmitLogin = (e)=>{
    e.preventDefault()
    setBtnLogin(true)
    axios
      .post('https://nodetlbnjib.tlbnjib.com/login', {Username, Password})
      .then((res) => {
        if(res.data.Status === 'Success'){
          //document.cookie = `token=${res.data.token}`;
          window.location.href = '/admin-area/dashboard';
        } else {
          alert(res.data)
        }
      })
      .catch((err) => console.log(err));
  }
  return (
    <div className="form">
    <form method="POST" onSubmit={handleSubmitLogin} className="form-input-login">
      <div className="input-container">
        <label>Username </label>
        <input className="input-form" value={Username} onChange={(e)=> setUsername(e.target.value)} type="text" name="uname" required />
      </div>
      <div className="input-container">
        <label>Password </label>
        <input className="input-form" value={Password} onChange={(e)=> setPassword(e.target.value)} type="password" name="pass" required />
      </div>
      <div className="button-container">
        <button className="btn-login">
          {
            BtnLogin ? (
              'Checking ...'
            ) : (
              'Login'
            )
          }
        </button>
      </div>
    </form>
  </div>
  );
}

export default Login;