import React, { useState, useEffect } from 'react'
import { AiOutlineRight } from "react-icons/ai";
import { BiSolidDiscount, BiCartAdd, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import './Popular.css'
import './PopularAr.css'
import axios from 'axios'
import { useLanguage } from '../../../../LanguageContext';

const Popular = (props) => {
    const { language } = useLanguage();
    const translations = require(`../../../../Language/${language}.json`);
  
    const [newProducts, setNewProducts] = useState([]);

    useEffect(() => {
      axios
        .get('https://nodetlbnjib.tlbnjib.com/products-popular')
        .then((res) => {
          setNewProducts(res.data);
        })
        .catch((error) => {
          console.log('error ' + error);
        });
    }, []);


    let [valueTransforme, setValeurTransforme] = useState(0)
    let transformMaxValue = 0
    let calcTotal = ()=>{
        for(let i = 0; i<=newProducts.length; i++){
            if(i>5){
                transformMaxValue += 100
            }else{
                transformMaxValue += 0
            }
        }
    }
    calcTotal()

    let handleLeft = ()=>{
        let targetValue = valueTransforme + 100
        setValeurTransforme(targetValue)
        
    }
    let handleRight = ()=>{
        let targetValue = valueTransforme - 100
        setValeurTransforme(targetValue)
    }
    const { cart, setCart } = props;

    const handleAddToCart = (id) => {
        const productToAdd = newProducts.find((product) => product.id === id);
        const productWithoutTotal = {
          id: productToAdd.id,
          name: productToAdd.name,
          newPrice: productToAdd.newPrice,
          isAvailable: productToAdd.isAvailable,
          quantity: 1,
          jamla: false
        };
        setCart([...cart, productWithoutTotal]);
    }
  return (
    <div className='popular-products'>
      <div className='popular-pro max-width-pop'>
        <div className='popular-vnt-flash'>
            <h2 className='title-popular'><BiSolidDiscount className='icon-pop' />{translations.venteFlash}</h2>
            <a href='/ventes-flash' className='voir-more-pop'>{translations.voirPlus}<AiOutlineRight className='icon-pop-right' /></a>
        </div>
        <div className='list-flash-product'>
            {
                newProducts.map((flashProduct, index)=>(
                    flashProduct.isAvailable > 0?(
                        <div className='product-flash' key={index} style={{transform: `translateX(${valueTransforme}%)`}}>
                            {
                                flashProduct.lastPrice>0?(
                                    <div className='promo'>
                                        <p className='p-prpmo'>PROMO</p>
                                    </div>
                                ):(
                                    null
                                )
                            }
                            <div className='img-produ-flash'>
                                <img src={`data:image/jpeg;base64,${flashProduct.mainImg}`} alt={flashProduct.name} className='img-flash' />
                            </div>
                            <div className='info-prod-flash'>
                                <a
                                  href={`/product/?name=${flashProduct.url}`}
                                  className="title-prod-f"
                                >
                                  {flashProduct.name}
                                </a>
                                <div className='price-cart-pop'>
                                    <div className='price-pop'>
                                        <span className='current-price-pop'>{flashProduct.newPrice}MAD</span>
                                        {
                                            flashProduct.lastPrice > 0 ? (
                                              <span className="last-price-pop">
                                                {flashProduct.lastPrice}MAD
                                              </span>
                                            ) : (
                                              null
                                            )
                                        }
                                    </div>
                                    <div className='addToCart-pop'>
                                        <BiCartAdd 
                                            className='icon-pop-cart' 
                                            onClick={() => handleAddToCart(flashProduct.id)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className='product-flash' key={index} style={{filter: 'opacity(0.5)',transform: `translateX(${valueTransforme}%)`}}>
                            <div className='pas-dis'>
                              <p className='p-pas'>{translations.PasDisponible}</p>
                            </div>
                            <div className='img-produ-flash'>
                                <img src={`data:image/jpeg;base64,${flashProduct.mainImg}`} alt={flashProduct.name} className='img-flash' />
                            </div>
                            <div className='info-prod-flash'>
                                <a
                                  href={`#`}
                                  className="title-prod-f"
                                >
                                  {flashProduct.name}
                                </a>
                                <div className='price-cart-pop'>
                                    <div className='price-pop'>
                                        <span className='current-price-pop'>{flashProduct.newPrice}MAD</span>
                                        <span className='last-price-pop'>{flashProduct.lastPrice}MAD</span>
                                    </div>
                                    <div className='addToCart-pop'>
                                        <BiCartAdd 
                                            className='icon-pop-cart' 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    
                ))
            }
        </div>
      </div>
    </div>
  )
}

export default Popular
