import React, { useState, useEffect } from 'react'
//style css
import './Header.css'
import './HeaderAr.css'
// icons
import { AiFillCustomerService, AiOutlineSearch, AiOutlineShopping } from "react-icons/ai";
//logo
import logo from '../../../../../assets/tlbn.jpg'
//import { useCart } from '../../Cart/cart';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useLanguage } from '../../../../LanguageContext';

const Header = (props) => {
    const { language, switchLanguage } = useLanguage();
    const translations = require(`../../../../Language/${language}.json`);
    const { numphone, cart } = props;
    const WebsiteName = "TLB N'JIB"
    const numberPhone = numphone;
    const currentLang = language

    const [categories, setcategories] = useState([])

    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-categoris`)
        .then((res) => {
          setcategories(res.data)
        })
        .catch((err) => console.log(err));
    }, [categories]);

    const [currentCategory, setCurrentCategory] = useState('');
    let nomberOrders = cart.length

    const [Search, setSearch] = useState('')

    const navigate = useNavigate();

    const handleClick = () => {
      const dataToSend = {
        cart
      };
      // Navigate to the "/product/checkout" route with data
      navigate('/checkout', { state: dataToSend });
    };

    const handlesearch = (e) => {
      e.preventDefault();
      // Navigate to the "/product/checkout" route with data
      navigate(`/products/?search=${Search}&categorie=${currentCategory}`);
      window.location.reload();
    };
  return (
    <header className='header'>
      <div className='header-top max-width'>
        <p className='header-top-wel'>{translations.titleWebSite}<span style={{color: 'var(--primary-color)'}}> {WebsiteName}</span></p>
        <div className='header-top-num-phone header-center'>
            <p className='number-context'><AiFillCustomerService className='icons-sty' />{translations.telephone}:<a className='numPhon' href={`tel:${numphone}`}>{numphone}</a></p>
        </div>
        <div className='lang-website header-end'>
            <span 
                className={
                    currentLang === 'fr' ?'lang-choose francais activeLang' :
                    'lang-choose francais'
                }
                onClick={()=> switchLanguage('fr')}
            >
                {
                    currentLang === 'fr'? 'Francais' : <a href='#francais'>Francais</a>
                }
                
            </span>
            <span 
                className={
                    currentLang === 'ar' ? 'lang-choose arabe activeLang fontFamilyAr' :
                    'lang-choose arabe fontFamilyAr'
                }
                onClick={()=> switchLanguage('ar')}

            >
                {
                    currentLang === 'ar'? 'العربية' : <a href='#arabe'>العربية</a>
                }
            </span>
        </div>
      </div>
      <div className='header-bottom max-width'>
        <div className='header-bottom-logo'>
          <a href='/'>
            <img src={logo} alt="TLB N'JIB" className='logo-img' />
          </a>
        </div>
        <div className='header-bottom-search'>
            <form className='form-Search' method='get'>
                <select name='category' className='select-categ' value={currentCategory} onChange={(e) => setCurrentCategory(e.target.value)}>
                    <option
                      value='Tout'
                      style={{
                          backgroundColor:
                            currentCategory === 'Tout' ? "var(--primary-color)" : null,
                          color:
                            currentCategory === 'Tout' ? "white" : null,
                      }}
                      className={language === 'ar'? 'fontFamilyAr': ''}
                    >
                      {translations.Tout}
                    </option>
                    {categories.map((category, index) => (
                      <option
                        key={index}
                        value={category.category}
                        className={language === 'ar'? 'fontFamilyAr': ''}
                        style={{
                          backgroundColor:
                            currentCategory === category.category ? "var(--primary-color)" : null,
                          color:
                            currentCategory === category.category ? "white" : null,
                        }}
                      >
                        {category.category}
                      </option>
                    ))}
                </select>
                <input type='text' onChange={(e)=>setSearch(e.target.value)} value={Search} name='search'  className={language === 'ar'? 'fontFamilyAr input-search': 'input-search'} placeholder={translations.Recherche} />
                <button name='search' onClick={handlesearch} className={language === 'ar'? 'btn-search fontFamilyAr' : 'btn-search'}>{translations.recherche}</button>
            </form>
        </div>
        <div onClick={handleClick} className='header-b-cart'>
            <span className='numOrders'>{nomberOrders}</span>
            <AiOutlineShopping className='icons-cart' />
        </div>
      </div>
    </header>
  )
}

export default Header;