import React, { useState, useEffect } from 'react'
import Slide from '../Slide/Slide'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import './Insights.css'
import axios from 'axios'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loading from "../../../loading/loading"

const Insights = () => {
  const [LoadingPage, setLoadingPage] = useState(true)
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/insight')
    .then(res=>{
      if(res.data.Status === 'Success'){
        setLoadingPage(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })
  const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  //analytics
  const [dataViews, setDataViews] = useState([]);

  const [todayViews, setTodayViews] = useState()
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/get-analytics-today')
      .then(res => {
        setTodayViews(res.data[0].count);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  //yesterday
  const [yesterdayViews, setYesterdayViews] = useState()
  useEffect(() => {
     axios.get('https://nodetlbnjib.tlbnjib.com/get-analytics-yesterday')
       .then(res => {
         setYesterdayViews(res.data[0].count);
       })
       .catch(error => {
         console.log(error);
       });
   }, []);
  //this week
  const [thisWeekViews, setThisWeekViews] = useState()
  useEffect(() => {
     axios.get('https://nodetlbnjib.tlbnjib.com/get-analytics-thisweek')
       .then(res => {
         setThisWeekViews(res.data[0].count);
       })
       .catch(error => {
         console.log(error);
       });
   }, []);
  //this month
  const [thisMonthViews, setThisMonthViews] = useState()
  useEffect(() => {
     axios.get('https://nodetlbnjib.tlbnjib.com/get-analytics-thismonth')
       .then(res => {
         setThisMonthViews(res.data[0].count);
       })
       .catch(error => {
         console.log(error);
       });
   }, []);
  //last month
  const [lastMonthViews, setLastMonthViews] = useState()
  useEffect(() => {
     axios.get('https://nodetlbnjib.tlbnjib.com/get-analytics-lastmonth')
       .then(res => {
         setLastMonthViews(res.data[0].count);
       })
       .catch(error => {
         console.log(error);
       });
   }, []);
  // Get the current date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${day}/${month}/${year}`;


  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/get-analytics-chartviews')
      .then(res => {
        setDataViews(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  const [popularSource, setPopularSource] = useState([]);

  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/get-most-source-link')
      .then(res => {
        setPopularSource(res.data);

      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const [popularCountries, setPopularCountries] = useState([]);

  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/get-most-popular-countries')
      .then(res => {
        setPopularCountries(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  if(LoadingPage){
    return (
      <Loading />
    );
  }
  return (
    <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
              <div className='welcome-bk'>
                <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
              </div>
              <div className='user-notification-con'>
                {/*<div className='notification-cont'>
                  <div className='notification-icon'>
                    <AiOutlineBell />
                    <span className='count-nofitication'>0</span>
                  </div>
                </div>*/}
                <div className='admin-cont'>
                  <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                  {
                    LogOutBtn ? (
                      <div className='logout'>
                        <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
            <div className='analytics siz-section'>
                <h3 className='title-analytcs font-f title-setction'>الاحصائيات</h3>
                <div className='views-analytics'>
                    <div className='chart-views-ana'>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                              width={500}
                              height={300}
                              data={dataViews}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name"/>
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="views" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='lastest-views table-des'>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className='font-f'>المشاهدات</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-f'>اليوم</td>
                                    <td className='font-f'>{todayViews}</td>
                                </tr>
                                <tr>
                                    <td className='font-f'>الامس</td>
                                    <td className='font-f'>{yesterdayViews}</td>
                                </tr>
                                <tr>
                                    <td className='font-f'>هذا الاسبوع</td>
                                    <td className='font-f'>{thisWeekViews}</td>
                                </tr>
                                <tr>
                                    <td className='font-f'>هذا الشهر</td>
                                    <td className='font-f'>{thisMonthViews}</td>
                                </tr>
                                <tr>
                                    <td className='font-f'>الشهر الفائت</td>
                                    <td className='font-f'>{lastMonthViews}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="analytics-detaile">
                    <div className="country-chart table-detail">
                      <h2 className='title-sec-an font-f'>اكثر 5 دول زيارة للمتجر</h2>
                      <table>
                            <thead>
                                <tr>
                                    <th className='font-f'>الدول</th>
                                    <th className='font-f'>مجموع المشاهدات</th>
                                    <th className='font-f'>نسبة الزوار من هذه الدولة (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {popularCountries.map(country => (
                                  <tr key={country.country}>
                                    <td className='font-f'>{country.country == ''? 'غير معروف' : country.country}</td>
                                    <td className='font-f'>{country.count}</td>
                                    <td className='font-f'>{country.percentage}%</td>
                                  </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
        
                    <div className="source-table table-detail">
                      <h2 className='title-sec-an font-f'>مصادر الزيارات</h2>
                      <table>
                        <thead>
                               <tr>
                                 <th className='font-f'>مصدر الزيارة</th>
                                 <th className='font-f'>مجموع المشاهدات</th>
                               </tr>
                        </thead>
                        <tbody>
                               {popularSource.map((source, index) => (
                                 <tr key={source.index}>
                                   <td className='font-f'><a href={source.link} target="_blank" rel="noopener noreferrer">{source.link == ''? 'غير معروف': source.link}</a></td>
                                   <td className='font-f'>{source.views}</td>
                                 </tr>
                               ))}
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Insights
