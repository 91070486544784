import React, { useState, useEffect } from 'react'
import Slide from '../Slide/Slide'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import './Orders.css'
import axios from 'axios'
import Loading from '../../../loading/loading'

const Orders = () => {
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/orders')
    .then(res=>{
      if(res.data.Status === 'Success'){
        
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })

  const [data, setdata] = useState([])
  const [activePoup, setactivePoup] = useState(false)
  const [IndexData, setIndex] = useState(0)
  const [isLoading, setLoading] = useState(true)

    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-order`)
        .then((res) => {
            setdata(res.data)
            setLoading(false)
        })
        .catch((err) => console.log(err));
  }, [data]);
  const [currentPayStatu, setcurrentPayStatu] = useState('')
  const [currentShippingStatu, setcurrentShippingStatu] = useState('')
  const [idOrder, setidOrder] = useState()
  const handleSubmit = (e)=>{
    e.preventDefault();
    axios
      .post('https://nodetlbnjib.tlbnjib.com/update-order', { currentPayStatu, currentShippingStatu, idOrder }) // Changed the object structure to send category
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }
  const handleDeleteOrder = (id)=>{
    axios.delete(`https://nodetlbnjib.tlbnjib.com/delete-order/${id}`)
    .then(res => {
      alert(res.data);
      window.location.reload();
    })
    .catch(err => console.log(err));
  }
  const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  if(isLoading){
    return <Loading />
  }
  return (
    <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
              <div className='welcome-bk'>
                <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
              </div>
              <div className='user-notification-con'>
                {/*<div className='notification-cont'>
                  <div className='notification-icon'>
                    <AiOutlineBell />
                    <span className='count-nofitication'>0</span>
                  </div>
                </div>*/}
                <div className='admin-cont'>
                  <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                  {
                    LogOutBtn ? (
                      <div className='logout'>
                        <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
            <div className='list-orders' onClick={()=> setLogOutBtn(false)}>
              <h3 className='title-setction font-f'>الطلبيات</h3>
              <div className='orders-search'>
                <div className='form-search'>
                  {/*<form method='post'>
                    <input type='text' name='order-name' className='input-order font-f' placeholder='البحث عن الطلبيات' />
                    <input type='text' name='product-name' className='input-order font-f' placeholder='البحث عن المنتجات' />
                    <select name="filter-search" className='input-order font-f'>
                      <option value="*">الكل</option>
                      <option value="open">قيد الانتظار</option>
                      <option value="closed">مغلق</option>
                      <option value="processed">تمت معالجتها</option>
                    </select>
                    <button className='btn-search-order font-f'>فلتر</button>
                  </form>*/}
                </div>
                <div className="table-container">
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th className='font-f'>المرجع</th>
                        <th className='font-f'>تاريخ الإنشاء</th>
                        <th className='font-f'>العميل</th>
                        <th className='font-f'>حالة الدفع</th>
                        <th className='font-f'>حالة الشحن</th>
                        <th className='font-f'>المجموع</th>
                        <th className='font-f'>اجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className='font-f' style={item.statuPay === 'قيد الانتظار'? {background: 'yellow'}: item.statuPay === 'مغلق'? {background: 'red', color: 'white'}: item.statuPay === 'تمت معالجتها'? {background: 'green', color: 'white'} : item.statuPay === 'قيد المعالجة'? {background: '#6b8c2b', color: 'white'} : {}}>#{item.id_order}</td>
                          <td className='font-f'>{item.date}</td>
                          <td className='font-f'>{item.fullName}</td>
                          <td className='font-f'>{item.statuPay}</td>
                          <td className='font-f'>{item.ShippingStatu}</td>
                          <td className='font-f'>{item.total} MAD</td>
                          <td className='font-f'>
                            <button className='btn-add-data font-f' onClick={()=> {
                              setIndex(index)
                              setactivePoup(true)
                            }} >
                              تفاصيل
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='add-data' style={activePoup? {display: 'flex'}: {display: 'none'}}>
                <div className='add-data-contxt order-pup'>
                      <form method='POST' onSubmit={handleSubmit}>
                          <h3 className='nom-order'>رقم الطلبية #{data[IndexData].id_order}</h3>
                          <div className='input-fata-read'>
                            <div className='info-inp'>
                              <label className='input-title font-f'>الاسم الكامل: </label>
                              <input 
                                type='text'
                                name='category' 
                                value={data[IndexData].fullName} 
                                className='input-add-data font-f' 
                                placeholder='اسم كوبون الجديد' 
                                readOnly
                              />
                            </div>
                            <div className='info-inp'>
                              <label className='input-title font-f'>تاريخ ارسال الطلبية: </label>
                              <input 
                                type='text'
                                name='category' 
                                value={data[IndexData].date} 
                                className='input-add-data font-f' 
                                readOnly
                              />
                            </div>
                          </div>
                          <div className='input-fata-read'>
                            <div className='info-inp'>
                              <label className='input-title font-f'>الكوبون المستخدم: </label>
                              <input 
                                type='text'
                                name='category' 
                                value={data[IndexData].Coupon} 
                                className='input-add-data font-f' 
                                readOnly
                              />
                            </div>
                            <div className='info-inp'>
                              <label className='input-title font-f'>رقم الهاتف: </label>
                              <input 
                                type='text'
                                name='category' 
                                value={data[IndexData].numPhone} 
                                className='input-add-data font-f' 
                                readOnly
                              />
                            </div>
                          </div>
                          <div className='input-fata-read'>
                            <div className='info-inp'>
                              <label className='input-title font-f'>المدينة: </label>
                              <input 
                                type='text'
                                name='category' 
                                value={data[IndexData].city} 
                                className='input-add-data font-f' 
                                readOnly
                              />
                            </div>
                            <div className='info-inp'>
                              <label className='input-title font-f'>عنوان الارسال: </label>
                              <input 
                                type='text'
                                name='category' 
                                value={data[IndexData].address} 
                                className='input-add-data font-f' 
                                readOnly
                              />
                            </div>
                          </div>
                          {JSON.parse(data[IndexData].dataProducts).map((product, index) => (
                            <div className='input-data-read' key={index}>
                              <div className='info-inp'>
                                <label className='input-title font-f'>اسم المنتج: </label>
                                <input
                                  type='text'
                                  name='product-name'
                                  value={`${product.name}`}
                                  className='input-add-data font-f'
                                  readOnly
                                />
                              </div>
                              <div className='info-inp'>
                                <label className='input-title font-f'>كمية: </label>
                                <input
                                  type='text'
                                  name='product-quantity'
                                  value={product.quantity}
                                  className='input-add-data font-f'
                                  readOnly
                                />
                              </div>
                              <div className='info-inp'>
                                <label className='input-title font-f'>ثمن القطعة الواحدة: </label>
                                <input
                                  type='text'
                                  name='product-price'
                                  value={`${product.newPrice} MAD`}
                                  className='input-add-data font-f'
                                  readOnly
                                />
                              </div>
                            </div>
                          ))}
                          <div className='input-fata-read info-inp'>
                            <label className='input-title font-f'>مجموع الطلبات بالدرهم: </label>
                            <input 
                              type='text'
                              name='category' 
                              value={`${data[IndexData].total} MAD`} 
                              className='input-add-data total-inp font-f' 
                              readOnly
                            />
                          </div>
                          <div className='input-fata-read'>
                            <div className='info-inp'>
                              <label className='input-title font-f'>حالة الطلبية: </label>
                              <select className='input-add-data font-f select-opy' value={currentPayStatu} onChange={(e) => setcurrentPayStatu(e.target.value)}>
                                <option className='font-f' value='-'>-</option>
                                <option className='font-f' value='قيد الانتظار'>قيد الانتظار</option>
                                <option className='font-f' value='مغلق'>مغلق</option>
                                <option className='font-f' value='تمت معالجتها'>تمت معالجتها</option>
                                <option className='font-f' value='قيد المعالجة'>قيد المعالجة</option>
                              </select>
                            </div>
                            <div className='info-inp'>
                              <label className='input-title font-f'>حالة الشحن: </label>
                              <select className='input-add-data font-f select-opy' value={currentShippingStatu} onChange={(e) => setcurrentShippingStatu(e.target.value)}>
                                <option className='font-f' value='-'>-</option>
                                <option className='font-f' value='لم يتم شحنها'>لم يتم شحنها</option>
                                <option className='font-f' value='تم ارجاع الطلبية'>تم ارجاع الطلبية</option>
                                <option className='font-f' value='تم شحن الطلبية'>تم شحن الطلبية</option>
                                <option className='font-f' value='تم تسليم الطلبية'>تمت معالجتها</option>
                              </select>
                            </div>
                          </div>

                          <button type='submit' onClick={()=> setidOrder(data[IndexData].id_order)} className='btn-add-data font-f'>حفظ</button>
                          <p className='btn-add-data remove-dt font-f' onClick={()=> handleDeleteOrder(data[IndexData].id_order)}>حذف</p>
                          <p className='btn-remove-pop font-f' onClick={()=> setactivePoup(false)}>الغاء</p>
                      </form>
                  </div>
            </div>
        </div>
    </div>
  )
}

export default Orders
