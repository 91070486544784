import React, { useState, useEffect } from 'react'
import { Header, Footer, Popular } from '../Home/HomeElements/index'
import axios from 'axios'
import { AiOutlineShopping, AiOutlineClose } from "react-icons/ai";
import './Checkout.css'
import './CheckoutAr.css'
import Loading from '../loading/loading'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';

const loadCartDataFromLocalStorage = () => {
  const savedCartData = localStorage.getItem('cart');
  if (savedCartData) {
    return JSON.parse(savedCartData);
  }
  return [];
};

const Checkout = () => {
  const { language } = useLanguage();
  const translations = require(`../../Language/${language}.json`);
  
  const [loadingScreen, setloadingScreen] = useState(true)
    // get main data
  const [data, setData] = useState({});
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/')
      .then(res => {
        setData(res.data);
        setloadingScreen(false)
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);

  const location = useLocation();

  // Access the data from the location's state property
  const [CartData, setCartData] = useState(loadCartDataFromLocalStorage());

  useEffect(() => {
    // Save cart data to local storage whenever it changes
    localStorage.setItem('cart', JSON.stringify(CartData));
  }, [CartData]);
  useEffect(() => {
    // Function to add the quantity property to cart items, but only if it doesn't exist
    const addQuantityToCartItems = (cartItems) => {
      return cartItems.map((item) => ({
        ...item,
        quantity: item.quantity || 1, // Set quantity to 1 if it doesn't exist
      }));
    };
  
    // Load cart data from local storage
    const loadedCartData = loadCartDataFromLocalStorage();
  
    // Update cart data with quantity property, but only if it doesn't exist
    const updatedCartData = addQuantityToCartItems(loadedCartData);
  
    // Set the updated cart data
    setCartData(updatedCartData);
  }, []);

  
  const handleQuantityChange = (index, newValue) => {
    const updatedCart = [...CartData];
    updatedCart[index].quantity = newValue;
    setCartData(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  const [TotalAchat, setTotalAchat] = useState(0);
  const [Coupon, setCoupon] = useState(0);

   const totalAchat = () => {
     let total = 0;
     for (let i = 0; i < CartData.length; i++) {
       total += CartData[i].newPrice * CartData[i].quantity;
     }
     setTotalAchat(total);
   };
    

   const [CouponSearch, setCouponSearch] = useState('')
   const HandleCoupon = (e)=>{
        e.preventDefault();
        axios
        .get(`https://nodetlbnjib.tlbnjib.com/search-coupon?CouponSearch=${CouponSearch}`)
        .then((res) => {
            if(res.data === "Ce code promo n'est pas disponible" || res.data[0].status === "Not Available" || res.data[0].maxUt<=0){
                alert("Ce code promo n'est pas disponible");
            } else {
                setCoupon(res.data[0].discountValue)
            }
          
        })
        .catch((err) => console.log(err));
    }
    const handleDelete = (index) => {
      // Create a copy of the CartData state
      const updatedCartData = [...CartData];
    
      // Remove the item at the specified index
      updatedCartData.splice(index, 1);
    
      // Update the CartData state with the modified array
      setCartData(updatedCartData);
    
      // Update localStorage to remove the item
      localStorage.setItem('cart', JSON.stringify(updatedCartData));
    };
  useEffect(()=>{
    totalAchat();
  })

  const navigate = useNavigate();
  const handleClickPay = () => {
    const CouponDataSend = {
      Coupon,
      CouponSearch
    };
    // Navigate to the "/product/checkout" route with data
    navigate('/checkout/information', { state: CouponDataSend });
  };

  return (
    <div dir={language === 'fr' ? "ltr" : "rtl"} className={language==='fr'? 'fontFamilyFr': 'fontFamilyAr ar'}>
      {
        loadingScreen ? (
          <Loading />
        )
        : (
          <>
            <Header numphone={data.numPhone} cart={CartData} />
              <div className='checkout-cart'>
                  <h2 className='title-check'><AiOutlineShopping /> {translations.MonPanier}</h2>
                  <div className='checkout-contnt'>
                      <div className='products-info'>
                      <table>
                        <thead>
                          <tr>
                            <th>{translations.Order1}</th>
                            <th>{translations.Order2}</th>
                            <th>{translations.Order3}</th>
                            <th>{translations.Order4}</th>
                            <th>{translations.Order5}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {CartData.map((data, index) => (
                            <tr key={index}>
                              <td>{data.name}</td>
                              <td>{data.newPrice} MAD</td>
                              <td>
                                <input
                                  type='number'
                                  value={data.quantity}
                                  min="0"
                                  onChange={(e) => {
                                    handleQuantityChange(index, parseInt(e.target.value))
                                    totalAchat();
                                  }}
                                  className={language === 'ar' ? 'input-qnt fontFamilyAr' : 'input-qnt'}
                                />
                              </td>
                              <td>{data.newPrice * data.quantity} MAD</td>
                              <td>
                                <AiOutlineClose onClick={()=>handleDelete(index)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </div>
                      <div className='continue-checkout'>
                          <form className='add-coupon'>
                              <form method='get'>
                                  <input 
                                      type='text' 
                                      name='CouponSearch' 
                                      placeholder={translations.Order7}
                                      className={language === 'ar' ? 'input-form-copn fontFamilyAr': 'input-form-copn'}
                                      value={CouponSearch}
                                      onChange={(e)=> setCouponSearch(e.target.value)}
                                  />
                                  <button className={language === 'ar'? 'btn-ajt-promo fontFamilyAr' : 'btn-ajt-promo'}  onClick={HandleCoupon}>{translations.Order8}</button>
                              </form>
                              <div className='info-py'>
                                  <p className='tit-info'>{translations.Order6}</p>
                                  <p className='cost-py'>{TotalAchat} MAD</p>
                              </div>
                              <div className='info-py'>
                                  <p className='tit-info'>{translations.Order7}</p>
                                  <p className='cost-py'>-{Coupon} %</p>
                              </div>
                              <div className='info-py'>
                                  <p className='tit-infot'>{translations.Order4}</p>
                                  <p className='cost-pyt'>{TotalAchat - (TotalAchat * (Coupon/100))} MAD</p>
                              </div>
                              <button className={language === 'ar' ? 'btn-contu-comm fontFamilyAr' : 'btn-contu-comm'} onClick={handleClickPay}>{translations.Order9}</button>
                          </form>
                      </div>
                  </div>
              </div>
              <Popular cart={CartData} setCart={setCartData} />
              <Footer numphone={data.numPhone} emaild={data.email} addressd={data.address} />
          </>
        )
      }
        
    </div>
  )
}

export default Checkout
