import React, {useState, useEffect} from 'react'
import Slide from '../Slide/Slide'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import './Coupon.css'
import axios from 'axios'
import Loading from '../../../loading/loading'

const Coupon = () => {
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/coupon')
    .then(res=>{
      if(res.data.Status === 'Success'){
        
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })
    const [data, setdata] = useState([])
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-coupon`)
        .then((res) => {
          setdata(res.data)
          setLoading(false)
        })
        .catch((err) => console.log(err));
    }, [data]);

    let [activeCopon, setactiveCopon] = useState(0)
    useEffect(() => {
        let count = 0;
        data.forEach(item => {
          if (item.Status === 'عملي') {
            count++;
          }
        });
        setactiveCopon(count);
    }, [data]);
    const [activePoup, setactivePoup] = useState(false)
    const [Coupon, setCoupon] = useState({
      code: '',
      descode: '',
      descount: '',
      startDate: '',
      endDate: '',
      limitUsed: ''
    })
    const handleSubmit = (e) => {
      e.preventDefault();
      axios
        .post('https://nodetlbnjib.tlbnjib.com/add-coupon', Coupon) // Changed the object structure to send category
        .then((res) => {
          alert(res.data);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    };

    const [activePoupUpdate, setactivePoupUpdate] = useState(false)
    const [IdActive, setIdActive] = useState(0)
    const [CouponUpdate, setCouponUpdate] = useState({
      code: undefined,
      descode: undefined,
      descount: undefined,
      startDate: '',
      endDate: '',
      limitUsed: undefined
    })
    const handleSubmitUpdate = (e) => {
      e.preventDefault();
      if (CouponUpdate.code === undefined) {
        CouponUpdate.code = data[IdActive].code;
      }
      if (CouponUpdate.descode === undefined) {
        CouponUpdate.descode = data[IdActive].desCode;
      }
      if (CouponUpdate.descount === undefined) {
        CouponUpdate.descount = data[IdActive].discountValue;
      }
      if (CouponUpdate.limitUsed === undefined) {
        CouponUpdate.limitUsed = data[IdActive].maxUt;
      }
      axios
        .post('https://nodetlbnjib.tlbnjib.com/update-coupon', CouponUpdate)
        .then((res) => {
          alert(res.data);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    };

    const handleDeleteCoupon = (id)=>{
      axios.delete(`https://nodetlbnjib.tlbnjib.com/delete-coupon/${id}`)
        .then(res => {
          alert(res.data);
          window.location.reload();
        })
        .catch(err => console.log(err));
    }
    const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  return (
    <div className='dashboard-main' dir='rtl'>
        {
          isLoading ? (
            <Loading />
          ) : (
            <>
            <Slide />
            <div className='dashboard-content'>
                <div className='info-user'>
                  <div className='welcome-bk'>
                    <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
                  </div>
                  <div className='user-notification-con'>
                    {/*<div className='notification-cont'>
                      <div className='notification-icon'>
                        <AiOutlineBell />
                        <span className='count-nofitication'>0</span>
                      </div>
                    </div>*/}
                    <div className='admin-cont'>
                      <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                      {
                        LogOutBtn ? (
                          <div className='logout'>
                            <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                          </div>
                        ) : (
                          null
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className='list-orders' onClick={()=> setLogOutBtn(false)} >
                  <h3 className='title-setction font-f'>كوبونات <span className='number-active-copon'>({activeCopon})</span></h3>
                  <div className='orders-search'>
                    <div className="table-container">
                      <table className="responsive-table">
                        <thead>
                          <tr>
                            <th className='font-f'>الرمز</th>
                            <th className='font-f'>الوصف</th>
                            <th className='font-f'>القيمة</th>
                            <th className='font-f'>تاريخ البدء</th>
                            <th className='font-f'>تاريخ الانتهاء</th>
                            <th className='font-f'>الأوقات المستخدمة</th>
                            <th className='font-f'>حد الاستخدام</th>
                            <th className='font-f'>الحالة</th>
                            <th className='font-f'>الإجراءات</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr key={index}>
                              <td className='font-f'>{item.code}</td>
                              <td className='font-f'>{item.desCode}</td>
                              <td className='font-f'>{item.discountValue}%-</td>
                              <td className='font-f'>{item.startDate}</td>
                              <td className='font-f'>{item.endDate}</td>
                              <td className='font-f'>{item.numUtil}</td>
                              <td className='font-f'>{item.maxUt}</td>
                              <td className='font-f'>{item.status}</td>
                              <td className='font-f'>
                                <button className='font-f bg-yellow' onClick={() => {
                                  setactivePoupUpdate(true)
                                  setIdActive(index)
                                }}>
                                  تفاصيل
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='add-data' style={activePoupUpdate? {display: 'flex'}: {display: 'none'}}>
                    <div className='add-data-contxt'>
                        <form method='POST' onSubmit={handleSubmitUpdate}>
                            <input 
                                type='text'
                                name='category' 
                                value={data[IdActive].code} 
                                className='input-add-data font-f' 
                                placeholder='اسم كوبون الجديد' 
                                readOnly
                            />
                            <input 
                                type='text'
                                name='desc' 
                                value={CouponUpdate.descode}
                                onChange={(e) => setCouponUpdate({...CouponUpdate, descode: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder={data[IdActive].desCode} 
                            />
                            <input 
                                type='number'
                                name='descount' 
                                value={CouponUpdate.descount}
                                onChange={(e) => setCouponUpdate({...CouponUpdate, descount: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder={data[IdActive].discountValue}
                            />
                            <input 
                                type='date'
                                name='startDate' 
                                value={CouponUpdate.startDate}
                                onChange={(e) => setCouponUpdate({...CouponUpdate, startDate: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='تاريخ البدء' 
                                required
                            />
                            <input 
                                type='date'
                                name='endDate' 
                                value={CouponUpdate.endDate}
                                onChange={(e) => setCouponUpdate({...CouponUpdate, endDate: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='تاريخ الانتهاء' 
                                required
                            />
                            <input 
                                type='number'
                                name='limit' 
                                value={CouponUpdate.maxUt}
                                onChange={(e) => setCouponUpdate({...CouponUpdate, maxUt: e.target.value, limitUsed: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder={data[IdActive].maxUt}
                            />
                            <button type='submit' className='btn-add-data font-f'>حفظ</button>
                            <p className='btn-add-data remove-dt font-f' onClick={()=> handleDeleteCoupon(data[IdActive].id)}>حذف</p>
                            <p className='btn-remove-pop font-f' onClick={()=> setactivePoupUpdate(false)}>الغاء</p>
                        </form>
                    </div>
                </div>
                <div className='add-data' style={activePoup? {display: 'flex'}: {display: 'none'}}>
                    <div className='add-data-contxt'>
                        <form method='POST' onSubmit={handleSubmit}>
                            <input 
                                type='text'
                                name='category' 
                                value={Coupon.code} 
                                onChange={(e) => setCoupon({...Coupon, code: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='اسم كوبون الجديد' 
                                required
                            />
                            <input 
                                type='text'
                                name='desc' 
                                value={Coupon.descode} 
                                onChange={(e) => setCoupon({...Coupon, descode: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='الوصف' 
                                required
                            />
                            <input 
                                type='number'
                                name='descount' 
                                value={Coupon.descount} 
                                onChange={(e) => setCoupon({...Coupon, descount: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='قيمة التخفيض %' 
                                required
                            />
                            <input 
                                type='date'
                                name='startDate' 
                                value={Coupon.startDate} 
                                onChange={(e) => setCoupon({...Coupon, startDate: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='تاريخ البدء' 
                                required
                            />
                            <input 
                                type='date'
                                name='endDate' 
                                value={Coupon.endDate} 
                                onChange={(e) => setCoupon({...Coupon, endDate: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='تاريخ الانتهاء' 
                                required
                            />
                            <input 
                                type='number'
                                name='limit' 
                                value={Coupon.limitUsed} 
                                onChange={(e) => setCoupon({...Coupon, limitUsed: e.target.value})}
                                className='input-add-data font-f' 
                                placeholder='اقصى عدد الاشخاص الذين سيستفيدون من الكوبون' 
                                required
                            />
                            <button type='submit' className='btn-add-data font-f'>حفظ</button>
                            <p className='btn-remove-pop font-f' onClick={()=> setactivePoup(false)}>الغاء</p>
                        </form>
                    </div>
                </div>
                <button className='add-col font-f' onClick={()=> setactivePoup(true)}>اضافة كوبون جديد</button>
            </div>
            </>
          )
        }
        
    </div>
  )
}

export default Coupon
