import React, { useState, useEffect } from 'react'
import Slide from '../../Slide/Slide'
import { AiOutlineUser, AiOutlineBell, AiOutlineClose } from "react-icons/ai";
import './Category.css'
import axios from 'axios'
import Loading from '../../../../loading/loading'

const Category = () => {
  const [isLoading, setLoading] = useState(true)

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/products/category')
    .then(res=>{
      if(res.data.Status === 'Success'){
        setLoading(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })


    const [data, setdata] = useState([])
    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-categoris`)
        .then((res) => {
            setdata(res.data)
        })
        .catch((err) => console.log(err));
    }, [data]);
    const handleDeleteCategory = (id)=>{
        axios.delete(`https://nodetlbnjib.tlbnjib.com/delete-category/${id}`)
          .then(res => {
            alert(res.data);
            window.location.reload();
          })
          .catch(err => console.log(err));
    }
    const [activePoup, setactivePoup] = useState(false)
    const [CategoryData, setCategory] = useState('')
    const handleSubmit = (e) => {
      e.preventDefault();
      axios
        .post('https://nodetlbnjib.tlbnjib.com/add-category', { category: CategoryData }) // Changed the object structure to send category
        .then((res) => {
          alert(res.data);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    };
    const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  if(isLoading){
    return (
      <Loading />
    );
  }
  return (
    <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
              <div className='welcome-bk'>
                <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
              </div>
              <div className='user-notification-con'>
                {/*<div className='notification-cont'>
                  <div className='notification-icon'>
                    <AiOutlineBell />
                    <span className='count-nofitication'>0</span>
                  </div>
                </div>*/}
                <div className='admin-cont'>
                  <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                  {
                    LogOutBtn ? (
                      <div className='logout'>
                        <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
            <div className='list-orders' onClick={()=> setLogOutBtn(false)}>
              <h3 className='title-setction font-f'>التصنيفات</h3>
              <div className='orders-search'>
                <div className="table-container">
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th className='font-f'>الاسم</th>
                        <th className='font-f'>تاريخ الإنشاء (MM/DD/YY)</th>
                        <th className='font-f'>الإجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className='font-f'>{item.category}</td>
                          <td className='font-f'>{item.date}</td>
                          <td className='font-f'>
                            <button className='delete-categ' onClick={()=> handleDeleteCategory(item.id)}>
                              <AiOutlineClose />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='add-data' style={activePoup? {display: 'flex'}: {display: 'none'}}>
                <div className='add-data-contxt'>
                    <form method='POST' onSubmit={handleSubmit}>
                        <input 
                            type='text'
                            name='category' 
                            value={CategoryData} 
                            onChange={(e)=> setCategory(e.target.value)}
                            className='input-add-data font-f' 
                            placeholder='اسم التصنيف الجديد' 
                            required
                        />
                        <button type='submit' className='btn-add-data font-f'>حفظ</button>
                        <p className='btn-remove-pop font-f' onClick={()=> setactivePoup(false)}>الغاء</p>
                    </form>
                </div>
            </div>
            <button className='add-col font-f' onClick={()=> setactivePoup(true)}>أضف تصنيف جديد</button>
        </div>
    </div>
  )
}

export default Category
