import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { AiFillDelete, AiFillEdit, AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import Slide from '../../Slide/Slide'
import './Listproducts.css'
import Loading from '../../../../loading/loading'

const Listproducts = () => {
  const [isLoading, setLoading] = useState(true)

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/products')
    .then(res=>{
      if(res.data.Status === 'Success'){
        setLoading(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })


  const [productsData, setproductsData] = useState([])
  useEffect(() => {
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/products`)
      .then((res) => {
        setproductsData(res.data)
      })
      .catch((err) => console.log(err));
  }, [productsData]);

  const handleDeleteProduct = (productId) => {
    axios.delete(`https://nodetlbnjib.tlbnjib.com/delete-product/${productId}`)
      .then(res => {
        alert(res.data);
        window.location.reload();
      })
      .catch(err => console.log(err));
  };
  const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  if(isLoading){
    return (
      <Loading />
    );
  }
  return (
    <div>
      <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
              <div className='welcome-bk'>
                <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
              </div>
              <div className='user-notification-con'>
                {/*<div className='notification-cont'>
                  <div className='notification-icon'>
                    <AiOutlineBell />
                    <span className='count-nofitication'>0</span>
                  </div>
                </div>*/}
                <div className='admin-cont'>
                  <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                  {
                    LogOutBtn ? (
                      <div className='logout'>
                        <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
            <div className='list-orders' onClick={()=> setLogOutBtn(false)}>
              <h3 className='title-setction font-f'>المنتجات</h3>
              <div className='orders-search'>
                <div className='form-search'>
                  {/*<form method='post'>
                    <input type='text' name='order-name' className='input-order font-f' placeholder='البحث عن المنتجات' />
                    <button className='btn-search-order font-f'>فلتر</button>
                  </form>*/}
                </div>
                <div className="table-container">
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th className='font-f'>الاسم</th>
                        <th className='font-f'>السعر</th>
                        <th className='font-f'>المخزون</th>
                        <th className='font-f'>الطلبيات</th>
                        <th className='font-f'>تاريخ الإنشاء</th>
                        <th className='font-f'>الأجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsData.map((item, index) => (
                        <tr key={index}>
                          <td className='font-f'>{item.name}</td>
                          <td className='font-f'>{item.newPrice} MAD</td>
                          <td className='font-f'>{item.isAvailable} قطعة</td>
                          <td className='font-f'>{item.totalOfSell}</td>
                          <td className='font-f'>{item.datePub}</td>
                          <td className='font-f'>
                            <a href={`/admin-area/products/create/?id=${item.id}`}>
                              <button className='edit-product'>
                                <AiFillEdit />
                              </button>
                            </a>
                            <button className='delete-product'  onClick={() => handleDeleteProduct(item.id)}>
                              <AiFillDelete />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Listproducts
