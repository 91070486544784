import React, {useState, useEffect} from 'react'
import Slide from '../Slide/Slide'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import './Message.css'
import axios from 'axios'
import Loading from '../../../loading/loading'

const Message = () => {
  const [isLoading, setLoading] = useState(true)
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/message')
    .then(res=>{
      if(res.data.Status === 'Success'){
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })
    const [data, setdata] = useState([])
    useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-contact`)
        .then((res) => {
          setdata(res.data)
          setLoading(false)
        })
        .catch((err) => console.log(err));
    }, [data]);

    const [activePoupUpdate, setactivePoupUpdate] = useState(false)
    const [IdActive, setIdActive] = useState(0)
    const handleSubmitUpdate = (id) => { 
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/read-contact/?id=${id}`)
        .then((res) => {

        })
        .catch((err) => console.log(err));
    };

    const handleDeleteCoupon = (id)=>{
      axios.delete(`https://nodetlbnjib.tlbnjib.com/delete-message/${id}`)
        .then(res => {
          alert(res.data);
          window.location.reload();
        })
        .catch(err => console.log(err));
    }
    

    const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  return (
    <div className='dashboard-main' dir='rtl'>
        {
          isLoading ? (
            <Loading />
          ) : (
            <>
                <Slide />
                <div className='dashboard-content'>
                    <div className='info-user'>
                      <div className='welcome-bk'>
                        <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
                      </div>
                      <div className='user-notification-con'>
                        {/*<div className='notification-cont'>
                          <div className='notification-icon'>
                            <AiOutlineBell />
                            <span className='count-nofitication'>0</span>
                          </div>
                        </div>*/}
                        <div className='admin-cont'>
                          <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                          {
                            LogOutBtn ? (
                              <div className='logout'>
                                <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                              </div>
                            ) : (
                              null
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className='list-orders' onClick={()=> setLogOutBtn(false)} >
                      <h3 className='title-setction font-f'>قائمة الرسائل</h3>
                      <div className='orders-search'>
                        <div className="table-container">
                          <table className="responsive-table">
                            <thead>
                              <tr>
                                <th className='font-f'>الاسم الكامل</th>
                                <th className='font-f'>الموضوع</th>
                                <th className='font-f'>التاريخ</th>
                                <th className='font-f'>الإجراءات</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item, index) => (
                                item.is_read ? (
                                    <tr key={index}>
                                        <td className='font-f'>{item.name}</td>
                                        <td className='font-f'>{item.subject}</td>
                                        <td className='font-f'>{item.date}</td>
                                        <td className='font-f'>
                                          <button className='font-f bg-yellow' onClick={() => {
                                            setactivePoupUpdate(true)
                                            setIdActive(index)
                                          }}>
                                            مشاهدة الرسالة
                                          </button>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr key={index} style={{background: 'var(--primary-color)', color: 'white'}}>
                                        <td className='font-f'>{item.name}</td>
                                        <td className='font-f'>{item.subject}</td>
                                        <td className='font-f'>{item.date}</td>
                                        <td className='font-f'>
                                          <button style={{background: 'white', color: 'black'}} className='font-f bg-yellow' onClick={() => {
                                            setactivePoupUpdate(true)
                                            handleSubmitUpdate(item.id)
                                            setIdActive(index)
                                          }}>
                                            مشاهدة الرسالة
                                          </button>
                                        </td>
                                    </tr>
                                )
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='add-data' style={activePoupUpdate? {display: 'flex'}: {display: 'none'}}>
                        <div className='message-data'>
                            <div className='info-gene'>
                                <p className='info-g font-f'>الاسم الكامل: {data[IdActive].name}</p>
                                <p className='info-g font-f'>الايميل: {data[IdActive].email}</p>
                                <p className='info-g font-f'>رقم الهاتف: {data[IdActive].phone}</p>
                                <p className='info-g font-f'>تاريخ الارسال: {data[IdActive].date}</p>
                            </div>
                            <p className='message-context'>{data[IdActive].message}</p>
                            <p className='close-pup font-f remove-message' onClick={()=> handleDeleteCoupon(data[IdActive].id)}>حذف</p>
                            <p className='close-pup font-f' onClick={()=>setactivePoupUpdate(false)}>إغلاق</p>
                        </div>
                    </div>
                </div>
            </>
          )
        }
        
    </div>
  )
}

export default Message