import React, { useState, useEffect } from 'react';
import Slide from '../Slide/Slide'
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import './Settings.css'; // Import the CSS file
import axios from 'axios'
import Loading from '../../../loading/loading'

const Settings = () => {
  const [isLoading, setLoading] = useState(true)

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/settings')
    .then(res=>{
      console.log(res.data)
      if(res.data.Status === 'Success'){
        setLoading(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })


  const [userSettings, setUserSettings] = useState({ username: '', password: '' });
  const [storeSettings, setStoreSettings] = useState({ storeName: '' });
  const [seoSettings, setSeoSettings] = useState({ metaDescription: '', metaKeywords: '' });
  const [logoSettings, setLogoSettings] = useState({ logoUrl: '' });
  const [contactSettings, setContactSettings] = useState({ phoneNumber: '', email: '' });

  const handleUserSettingsChange = (e) => {
    setUserSettings({ ...userSettings, [e.target.name]: e.target.value });
  };

  const handleStoreSettingsChange = (e) => {
    setStoreSettings({ ...storeSettings, [e.target.name]: e.target.value });
  };

  const handleSeoSettingsChange = (e) => {
    setSeoSettings({ ...seoSettings, [e.target.name]: e.target.value });
  };

  const handleLogoSettingsChange = (e) => {
    setLogoSettings({ ...logoSettings, [e.target.name]: e.target.value });
  };

  const handleContactSettingsChange = (e) => {
    setContactSettings({ ...contactSettings, [e.target.name]: e.target.value });
  };

  const handleSubmitemailPhone = (e)=>{
    e.preventDefault();
    axios
      .post('https://nodetlbnjib.tlbnjib.com/update-emil-phone', { phone: contactSettings.phoneNumber, email: contactSettings.email })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  const handleSubmitSEO = (e)=>{
    e.preventDefault();
    axios
      .post('https://nodetlbnjib.tlbnjib.com/update-seo', { keyword: seoSettings.metaKeywords, desc: seoSettings.metaDescription })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  const handleSubmitUserPass = (e)=>{
    e.preventDefault();
    axios
      .post('https://nodetlbnjib.tlbnjib.com/update-user-pass', { username: userSettings.username, pass: userSettings.password })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }
  const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  const [facebook, setfacebook] = useState('')
  const [Instagram, setInstagram] = useState('')
  const [whatsapp, setwhatsapp] = useState('')

  useEffect(() => {
    axios.get(`https://nodetlbnjib.tlbnjib.com/`)
      .then(res => {
        setfacebook(res.data.facebook)
        setInstagram(res.data.instagram)
        setwhatsapp(res.data.whatsapp)

        setLoading(false)
      })
      .catch(error => {
        console.log('error ' + error);
      });
  }, []);
  const handleSocialMedia = (e)=>{
    e.preventDefault();
    axios
      .post('https://nodetlbnjib.tlbnjib.com/update-social-media', { facebook, Instagram, whatsapp })
      .then((res) => {
        alert(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }
  if(isLoading){
    return (
      <Loading />
    );
  }

  return (
    <div className='dashboard-main' dir='rtl'>
        <Slide />
        <div className='dashboard-content'>
            <div className='info-user'>
                <div className='welcome-bk'>
                  <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
                </div>
                <div className='user-notification-con'>
                    {/*<div className='notification-cont'>
                      <div className='notification-icon'>
                        <AiOutlineBell />
                        <span className='count-nofitication'>0</span>
                      </div>
                    </div>*/}
                    <div className='admin-cont'>
                      <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
                      {
                        LogOutBtn ? (
                          <div className='logout'>
                            <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                          </div>
                        ) : (
                          null
                        )
                      }
                    </div>
                </div>
            </div>
            <div className="settings-section" onClick={()=> setLogOutBtn(false)}>
              <h2 className="section-title font-f">إعدادات المتجر</h2>
              <div className="form-section">
                <h3 className="subsection-title font-f">تغيير اسم المستخدم وكلمة المرور</h3>
                <form method='POST' onSubmit={handleSubmitUserPass}>
                  <input
                    type="text"
                    name="username"
                    placeholder="اسم المستخدم الجديد"
                    value={userSettings.username}
                    onChange={handleUserSettingsChange}
                    className='font-f input-settings'
                  />
                  <input
                    type="password"
                    name="password"
                    placeholder="كلمة المرور الجديدة"
                    value={userSettings.password}
                    onChange={handleUserSettingsChange}
                    className='font-f input-settings'
                  />
                  <button className='font-f btn-save'>حفظ</button>
                </form>
              </div>
              <div className="form-section">
                <h3 className="subsection-title font-f">وسائل التواصل الاجتماعي</h3>
                <form method='POST' onSubmit={handleSocialMedia}>
                  <input
                    type="text"
                    name="facebook"
                    placeholder="رابط الفيسبوك"
                    value={facebook}
                    onChange={(e)=> setfacebook(e.target.value)}
                    className='font-f input-settings'
                  />
                  <input
                    type="text"
                    name="Instagram"
                    placeholder="رابط الانستغرام"
                    value={Instagram}
                    onChange={(e)=> setInstagram(e.target.value)}
                    className='font-f input-settings'
                  />
                  <input
                    type="text"
                    name="whatsapp"
                    placeholder="رابط واتساب"
                    value={whatsapp}
                    onChange={(e)=> setwhatsapp(e.target.value)}
                    className='font-f input-settings'
                  />
                  <button className='font-f btn-save'>حفظ</button>
                </form>
              </div>
              <div className="form-section">
                <h3 className="subsection-title font-f">إضافة وصف وكلمات دلالية (SEO)</h3>
                <form method='POST' onSubmit={handleSubmitSEO}>
                  <input
                    type="text"
                    name="metaDescription"
                    placeholder="الوصف الوجيز للصفحة"
                    value={seoSettings.metaDescription}
                    onChange={handleSeoSettingsChange}
                    className='font-f input-settings'
                  />
                  <input
                    type="text"
                    name="metaKeywords"
                    placeholder="الكلمات الدلالية (مفصولة بفواصل)"
                    value={seoSettings.metaKeywords}
                    onChange={handleSeoSettingsChange}
                    className='font-f input-settings'
                  />
                  <button className='font-f btn-save'>حفظ</button>
                </form>
              </div>
              <div className="form-section">
                <h3 className="subsection-title font-f">تغيير رقم الهاتف والبريد الإلكتروني</h3>
                <form method='POST' onSubmit={handleSubmitemailPhone}>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="رقم الهاتف الجديد"
                    value={contactSettings.phoneNumber}
                    onChange={handleContactSettingsChange}
                    className='font-f input-settings'
                  />
                  <input
                    type="text"
                    name="email"
                    placeholder="البريد الإلكتروني الجديد"
                    value={contactSettings.email}
                    onChange={handleContactSettingsChange}
                    className='font-f input-settings'
                  />
                  <button className='font-f btn-save'>حفظ</button>
                </form>
              </div>
            </div>
        </div>
    </div>
  );
};

export default Settings;