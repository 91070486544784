import React from 'react'
import './Footer.css'
import './FooterAr.css'
import logo from '../../../../../assets/tlbntr.png'
import { useLanguage } from '../../../../LanguageContext';
import { AiFillFacebook, AiFillInstagram, AiOutlineWhatsApp } from "react-icons/ai";

const Footer = (props) => {
    const { language } = useLanguage();
    const translations = require(`../../../../Language/${language}.json`);
    const { numphone, emaild, addressd, facebook, instagram, whatsapp } = props;
    const address = addressd
    const email = emaild
    const phone = numphone
    const socialMedia = [
        {
            id: 0,
            url: facebook,
            icons: <AiFillFacebook />
        },
        {
            id: 1,
            url: instagram,
            icons: <AiFillInstagram />
        },
        {
            id: 2,
            url: whatsapp,
            icons: <AiOutlineWhatsApp />
        }
    ]
    const aboutNous = [
        {
            id:0,
            name: translations.footer2,
            link: '/qui-sommes-nous'
        },
        {
            id:1,
            name: translations.footer3,
            link: '/contact'
        },
        {
            id:1,
            name: translations.footer4,
            link: '/discount'
        }
    ]
    const informations = [
        {
            id: 0,
            name: translations.footer6,
            link: '/contact'
        },
        {
            id: 2,
            name: translations.footer8,
            link: '/politique-de-confidentialité'
        },
        {
            id: 3,
            name: translations.footer9,
            link: '/conditions-utilisation'
        },
    ]
    const payLivraison = [
        {
            id: 0,
            name: translations.footer11,
            link: '/methodes-de-payement'
        },
        {
            id: 1,
            name: translations.footer12,
            link: '/informations-de-livraison'
        },
        {
            id: 2,
            name: translations.footer13,
            link: '/lieux-vers-lesquels-nous-expedions'
        },
    ]

  return (
    <footer className='footer'>
      <div className='footer-top max-width'>
        <div className='footer-top-contxt info-fb'>
            <img src={logo} alt="TLB N'JIB" className='img-logo-fb' />
            <p className='info-content-fb'>{translations.Add}: {address}</p>
            <p className='info-content-fb'>{translations.Email}: {email}</p>
            <p className='info-content-fb'>{translations.telephone}: {phone}</p>
            <div className='social-media-link'>
                {
                    socialMedia.map((data, index)=>(
                        <div className='social-media' key={index}>
                            <a href={data.url} target='_blanck'>
                                {data.icons}
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className='footer-top-contxt nous-link'>
            <h5 className='fb-title'>{translations.footer1}</h5>
            <ul>
                {
                    aboutNous.map((aboutNou, index)=>(
                        <li key={index}><a href={aboutNou.link}>{aboutNou.name}</a></li>
                    ))
                }
            </ul>
        </div>
        <div className='footer-top-contxt infor-link'>
            <h5 className='fb-title'>{translations.footer5}</h5>
            <ul>
                {
                    informations.map((information, index)=>(
                        <li key={index}><a href={information.link}>{information.name}</a></li>
                    ))
                }
            </ul>
        </div>
        <div className='footer-top-contxt shopyPy-link'>
            <h5 className='fb-title'>{translations.footer10}</h5>
            <ul>
                {
                    payLivraison.map((payLivraison, index)=>(
                        <li key={index}><a href={payLivraison.link}>{payLivraison.name}</a></li>
                    ))
                }
            </ul>
        </div>
      </div>
      <div className='footer-bottom'>
        <p>Copyright © <span style={{color: 'var(--primary-color)'}}>TLB N'JIB</span>. All Rights Reserved. Programmed By <a href='#' style={{color: 'var(--primary-color)'}}>Yassine OUCHNID</a></p>
      </div>
    </footer>
  )
}

export default Footer
