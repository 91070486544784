import React, { useState, useEffect } from 'react'
import './slideShow.css'
import './slideShowAr.css'
import { AiOutlineRight, AiOutlineLeft, AiOutlineMenu } from "react-icons/ai";
import axios from 'axios'
import { useLanguage } from '../../../../LanguageContext';

const SlideShow = () => {
  const { language } = useLanguage();
  const translations = require(`../../../../Language/${language}.json`);
  const [categories, setcategories] = useState([])

  useEffect(() => {
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-categoris`)
      .then((res) => {
        setcategories(res.data)
      })
      .catch((err) => console.log(err));
  }, [categories]);


  let [ActiveSlide, IsActiveSlide] = useState(0)
  let [isDataGet, IsisDataGet] = useState(false)

  const [dataSlider, setdataSlider] = useState([])
  useEffect(() => {
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/products-popular-offer`)
      .then((res) => {
        setdataSlider(res.data)
        IsisDataGet(true)
      })
      .catch((err) => console.log(err));
  }, []);

  const handleRight = ()=>{
    if(ActiveSlide === dataSlider.length - 1){
      IsActiveSlide(0)
    }else{
      IsActiveSlide(ActiveSlide + 1)
    }
  }
  const handleLeft = ()=>{
    if(ActiveSlide === 0){
      const lastData = dataSlider.length - 1
      IsActiveSlide(lastData)
    }else{
      IsActiveSlide(ActiveSlide - 1)
    }
  }

  
  const addOne = ()=>{
    if(ActiveSlide === dataSlider.length - 1){
      IsActiveSlide(0)
    }else if(ActiveSlide === 0){
      const lastData = dataSlider.length - 1
      IsActiveSlide(lastData)
    }else{
      IsActiveSlide(ActiveSlide + 1)
    }
  }
  useEffect(()=>{
    if(isDataGet){
      setTimeout(addOne, 5000)
    }
  })
  return (
    <div className='cate-slide'>
      <div className='categ-slider max-width'>
        <div className='category-list-slid'>
          <h3 className='title-categ-slid'><AiOutlineMenu className='iconCate' />{translations.ToutesCategories}</h3>
          <ul>
            {categories.map((categorie, index)=>(
              <li key={index}><a href={`/products?category=${categorie.category}`}>{categorie.category}</a></li>
            ))}
          </ul>
        </div>
        <div className='sliderShow'>
          <span className='left-arrow arrow-slid' onClick={handleLeft}>{language === 'fr'? <AiOutlineLeft className='arrow-icon' /> : <AiOutlineRight className='arrow-icon' />}</span>
          {
            dataSlider.map((dataslide, index)=>(
              <div className={ActiveSlide === index? 'offer-product' : 'Inactive'} key={index}>
                <div className='offer-content'>
                  <h4 className={ActiveSlide === index? 'product-category-offer activeContent' : 'product-category-offer inActiveContent'}>{translations.Remise} <span style={{color: 'var(--primary-color)',fontWeight: 900}}>{Math.floor(dataslide.discount)}%</span></h4>
                  <h2 className={ActiveSlide === index? 'product-name-offer activeContent' : 'product-name-offer inActiveContent'}>{dataslide.name}</h2>
                  <button className={ActiveSlide === index? 'btn-shop-offer activeContent' : 'btn-shop-offer inActiveContent'}>
                    <span><a href={`/product/?name=${dataslide.url}`} style={{color: 'white'}} className={language === 'ar'? 'fontFamilyAr': ''}>{translations.ShopNow}</a></span>
                  </button>
                </div>
                <div className={ActiveSlide === index? 'offer-img activeImage' : 'offer-img inactiveImage'}>
                  <img src={`data:image/jpeg;base64,${dataslide.mainImg}`} alt={dataslide.name} className={ActiveSlide === index? 'offer-img activeImage' : 'offer-img inactiveImage'} />
                </div>
              </div>
            ))
          }
          <span className='right-arrow arrow-slid' onClick={handleRight}>{language === 'fr'? <AiOutlineRight className='arrow-icon' /> : <AiOutlineLeft className='arrow-icon' />}</span>
        </div>
      </div>
    </div>
  )
}

export default SlideShow
