import React, {useState, useEffect} from 'react'
import Slide from '../Slide/Slide'
import './Home.css'
import { AiOutlineUser, AiOutlineBell, AiOutlineInbox, AiOutlineDollar } from "react-icons/ai";
import axios from 'axios'
import Loading from "../../../loading/loading"

const HomeDashboard = () => {
  const [LoadingPage, setLoadingPage] = useState(true)
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('https://nodetlbnjib.tlbnjib.com/admin-area/dashboard')
    .then(res=>{
      if(res.data.Status === 'Success'){
        setLoadingPage(false)
      } else {
        window.location.href = '/admin-area/login';
      }
    })
  })

  const [AllViews, setAllViews] = useState()
  const [TodayOrder, setTodayOrder] = useState()
  const [YesterdayOrder, setYesterdayOrder] = useState()
  const [thisWeek, setthisWeek] = useState()
  const [thisMonth, setthisMonth] = useState()
  const [thisYear, setthisYear] = useState()
  useEffect(() => {
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-views/today`)
        .then((res) => {
          setTodayOrder(res.data.orders)
        })
        .catch((err) => console.log(err));

      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-views/yesterday`)
        .then((res) => {
          setYesterdayOrder(res.data.orders)
        })
        .catch((err) => console.log(err));
    
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-views/this-week`)
        .then((res) => {
          setthisWeek(res.data.orders)
        })
        .catch((err) => console.log(err));
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-views/this-month`)
        .then((res) => {
          setthisMonth(res.data.orders)
        })
        .catch((err) => console.log(err));
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-views/this-year`)
        .then((res) => {
          setthisYear(res.data.orders)
        })
        .catch((err) => console.log(err));
    
      axios
        .get(`https://nodetlbnjib.tlbnjib.com/get-views/all-time`)
        .then((res) => {
          setAllViews(res.data.orders)
        })
        .catch((err) => console.log(err));
  });

  const [AllViewsAmount, setAllViewsAmount] = useState()
  const [TodayOrderAmount, setTodayOrderAmount] = useState()
  const [YesterdayOrderAmount, setYesterdayOrderAmount] = useState()
  const [thisWeekAmount, setthisWeekAmount] = useState()
  const [thisMonthAmount, setthisMonthAmount] = useState()
  const [thisYearAmount, setthisYearAmount] = useState()
  useEffect(() => {
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-amount-mad/today`)
      .then((res) => {
        setTodayOrderAmount(res.data.orders)
      })
      .catch((err) => console.log(err));

    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-amount-mad/yesterday`)
      .then((res) => {
        setYesterdayOrderAmount(res.data.orders)
      })
      .catch((err) => console.log(err));
  
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-amount-mad/this-week`)
      .then((res) => {
        setthisWeekAmount(res.data.orders)
      })
      .catch((err) => console.log(err));
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-amount-mad/this-month`)
      .then((res) => {
        setthisMonthAmount(res.data.orders)
      })
      .catch((err) => console.log(err));
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-amount-mad/this-year`)
      .then((res) => {
        setthisYearAmount(res.data.orders)
      })
      .catch((err) => console.log(err));
  
    axios
      .get(`https://nodetlbnjib.tlbnjib.com/get-amount-mad/all-time`)
      .then((res) => {
        setAllViewsAmount(res.data.orders)
      })
      .catch((err) => console.log(err));
  });

  const [LogOutBtn, setLogOutBtn] = useState(false)
  const LogOut = ()=>{
    axios.get('https://nodetlbnjib.tlbnjib.com/logout')
    .then(res=>{
      window.location.reload();
    }).catch(err => console.log(err))
  }
  if(LoadingPage){
    return (
      <Loading />
    );
  }
  return (
    <div className='dashboard-main' dir='rtl'>
      <Slide />
      <div className='dashboard-content'>
        <div className='info-user'>
          <div className='welcome-bk'>
            <h4 className='con-title-wlcm font-f'>مرحبا بعودتك Yassine</h4>
          </div>
          <div className='user-notification-con'>
            {/*<div className='notification-cont'>
              <div className='notification-icon'>
                <AiOutlineBell />
                <span className='count-nofitication'>0</span>
              </div>
            </div>*/}
            <div className='admin-cont'>
              <AiOutlineUser onClick={()=> setLogOutBtn(true)} />
              {
                LogOutBtn ? (
                  <div className='logout'>
                    <button className='btn-logout font-f' onClick={LogOut}>تسجيل الخروج</button>
                  </div>
                ) : (
                  null
                )
              }
            </div>
          </div>
        </div>
        <div className='earning-orders' onClick={()=> setLogOutBtn(false)}>
          <h3 className='tit-earning-orders font-f'>نظرة عامة على لوحة التحكم</h3>
          <div className='listor-list-ern'>
            <div className='listor'>
              <h5 className='tit-listor font-f'><AiOutlineInbox /> الطلبيات</h5>
              <div className='list-ho-dash'>
                <div className='column-order'>
                  <p className='sub-time font-f'>اليوم</p>
                  <p className='num-or font-f'>{TodayOrder}</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>الامس</p>
                  <p className='num-or font-f'>{YesterdayOrder}</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>هذا الاسبوع</p>
                  <p className='num-or font-f'>{thisWeek}</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>هذ الشهر</p>
                  <p className='num-or font-f'>{thisMonth}</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>هذا العام</p>
                  <p className='num-or font-f'>{thisYear}</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>كل الاوقات</p>
                  <p className='num-or font-f'>{AllViews}</p>
                </div>
              </div>
            </div>
            <div className='listor'>
              <h5 className='tit-listor font-f'><AiOutlineDollar /> الأرباح</h5>
              <div className='list-ho-dash'>
              <div className='column-order'>
                  <p className='sub-time font-f'>اليوم</p>
                  <p className='num-or font-f'>{TodayOrderAmount == null ? '0': TodayOrderAmount} MAD</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>الامس</p>
                  <p className='num-or font-f'>{YesterdayOrderAmount == null ? '0': YesterdayOrderAmount} MAD</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>هذا الاسبوع</p>
                  <p className='num-or font-f'>{thisWeekAmount == null ? '0': thisWeekAmount} MAD</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>هذ الشهر</p>
                  <p className='num-or font-f'>{thisMonthAmount == null ? '0': thisMonthAmount} MAD</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>هذا العام</p>
                  <p className='num-or font-f'>{thisYearAmount == null ? '0': thisYearAmount} MAD</p>
                </div>
                <div className='column-order'>
                  <p className='sub-time font-f'>كل الاوقات</p>
                  <p className='num-or font-f'>{AllViewsAmount == null ? '0': AllViewsAmount} MAD</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeDashboard
